import loadable from '@loadable/component';
import {SliceNames} from './utils';

// Reminder clean this up
/* eslint-disable camelcase */
const COMPONENTS = {
    [SliceNames.FAQ]: loadable(() => import('./components/faq')),
    [SliceNames.FEATURED_ICONS]: loadable(() =>
        import('./components/featured-icons')
    ),
    [SliceNames.INFO_BANNER]: loadable(() =>
        import('./components/info-banner')
    ),
    [SliceNames.TEXT_ME_THE_APP]: loadable(() =>
        import('./components/text-me-the-app')
    ),
};

export default COMPONENTS;
