import APIUtils from '@spothero/utils/api';
import ErrorUtils from 'utils/error-utils';
const include = 'default_image,has_upcoming_events,google_places,deep_links,parent_url,meta_title,monthly_meta_title,meta_description,monthly_meta_description,destination,monthly_description,monthly_relative_url,monthly_short_description,desktop_zoom_level,mobile_zoom_level,monthly_desktop_zoom_level,monthly_mobile_zoom_level,default_map_zoom_level,airport,hide_event_modal';
const AirportAPI = {
    async getAirports() {
        try {
            const airports = (await APIUtils.get('/airports/'));
            try {
                return airports.data.data.results;
            }
            catch (error) {
                throw new Error('Response shape unexpected.');
            }
        }
        catch (e) {
            throw ErrorUtils.transformAndReject(e);
        }
    },
    getByCode(code) {
        return APIUtils.get(`airports/${code}/`, { include });
    },
};
export default AirportAPI;
