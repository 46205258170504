import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import {createReduxEnhancer} from '@sentry/react';
import {getWindow} from 'ssr-window';
import StorageUtils from '@spothero/utils/storage';
import createHistory from 'utils/history';
import Config from '@/config/index';
import {analyticsMiddleware} from './middleware/analytics';
import {routerMiddleware} from './middleware/router';
import {searchRequestMiddleware} from './middleware/search-request';
import {reduxLoggerMiddleware} from './middleware/redux-logger';
import reducers from './reducers';
import {initialState as cityInitialState} from './city/city-reducer';
import {initialState as destinationInitialState} from './destination/destination-reducer';
import {initialState as eventInitialState} from './event/event-reducer';
import {initialState as searchRequestInitialState} from './search-request/search-request-reducer';
import {initialState as checkoutInitialState} from './checkout/checkout-reducer';
import {initialState as pageInitialState} from './page/page-reducer';
import {initialState as cmsInitialState} from './cms/cms-reducer';
import {getReduxEnhancerOptions} from 'utils/sentry-redux';
import {getSearchVehicleFromSession} from 'utils/search-vehicle';

const window = getWindow();
let store;
const history = createHistory({basename: Config.basename});
const composeEnhancers =
    // eslint-disable-next-line no-underscore-dangle
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        // eslint-disable-next-line no-undef
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true})) ||
    compose; // eslint-disable-line no-underscore-dangle
const middleware = [
    routerMiddleware(history),
    thunkMiddleware,
    promiseMiddleware,
    analyticsMiddleware,
    searchRequestMiddleware,
];
const sentryReduxEnhancer = createReduxEnhancer(getReduxEnhancerOptions());

if (StorageUtils.get('sh-debug-logger', 'cookie')) {
    middleware.push(reduxLoggerMiddleware);
}

export default function create(initialState) {
    // initialize the vehicle profile from session storage
    if (typeof window !== 'undefined' && initialState?.search?.data) {
        initialState.search.data.vehicle = getSearchVehicleFromSession();
    }

    store = createStore(
        reducers,
        initialState,
        composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer)
    );

    return store;
}

// create a store logger middleware that will log all actions and state changes
export const createLogger = () => {
    // eslint-disable-next-line no-shadow
    const logger = store => next => action => {
        const result = next(action);

        // eslint-disable-next-line no-console
        console.log('dispatching', action);

        // eslint-disable-next-line no-console
        console.log('next state', store.getState());

        return result;
    };

    return logger;
};

export function createMockStore(initialState, routerHistory) {
    const mockMiddleware = [
        routerMiddleware(routerHistory),
        thunkMiddleware,
        promiseMiddleware,
        analyticsMiddleware,
        searchRequestMiddleware,
        // Uncomment this line to log all actions and state changes in console
        // createLogger(),
    ];

    store = createStore(
        reducers,
        initialState,
        composeEnhancers(applyMiddleware(...mockMiddleware))
    );

    return store;
}

export const getStore = () => store;

export const getHistory = () => history;

export function getInitialState() {
    return {
        city: cityInitialState(),
        destination: destinationInitialState(),
        event: eventInitialState(),
        searchRequest: {...searchRequestInitialState},
        page: pageInitialState(),
        cms: cmsInitialState(),
        checkout: checkoutInitialState(),
    };
}
