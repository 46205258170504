import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import StorageUtils from '@spothero/utils/storage';
import AuthenticationAPI from 'api/authentication';
import Config from 'config/index';
import {getStore} from 'store/store';
import {userUpdate} from 'store/user/user-actions';
import UserUtils from 'utils/user-utils';
import SegmentUtils from 'utils/segment';
import trackSignedUp from 'segment/events/signed-up';
import trackErrorMessageDisplayed from 'segment/events/error-message-displayed';

// TODO MR - This needs segment to be migrated first

const COOKIES = {
    AUTH_TOKEN: 'AUTH_TOKEN',
    SESSION_ID: 'sessionid',
    SESSION_UUID: 'sh-session-uuid',
    IMPERSONATE: 'IMPERSONATE',
    SH_LOGOUT: 'sh-slo',
    SH_GUEST: 'sh-auth-guest',
};

/**
 * @module utils/authentication
 */

/**
 * Handles on Success
 *
 * @param {object} response - Response object
 * @param {Function} callback - The callback that handles the response. but also does a check for callback to be a function
 */
const onHandleSuccess = (response, callback) => {
    // TODO MR - below looks like a code smell, may be worth investigating
    if (response.data && response.data.data) {
        response.data = response.data.data;
    }

    if (isFunction(callback)) {
        callback(response);
    }
};

/**
 * Handles on Failure
 *
 * @param {object} error - Error object
 * @param {Function} callback - The callback that handles the response. but also does a check for callback to be a function
 */
const onHandleFailure = (error, callback) => {
    let errors;

    if (error.data && error.data.data) {
        error.data = error.data.data;
    }

    if (isFunction(callback)) {
        try {
            errors = error.data.errors;
        } catch (e) {
            errors = false;
        }

        if (errors && error.status !== 500) {
            callback(errors);
        } else {
            callback([]);
        }
    }
};

/**
 * Handling for authentication Success
 *
 * @param {object} param - top level param
 * @param {Function} param.callback - Callback function passed into onHandleFailure
 * @param {string} param.socialAuthMethod - type of social auth
 * @param {object} param.user - user object returned
 * @returns {void}
 */
const onAuthenticationSuccess = ({user, callback, socialAuthMethod = null}) => {
    if (!isEmpty(user)) {
        // Identify user before track call
        getStore().dispatch(
            userUpdate(
                UserUtils.normalize({
                    ...user,
                    status: UserUtils.AUTH_STATE.USER,
                })
            )
        );

        if (user.justCreated) {
            trackSignedUp({
                email: user.email,
                socialNetworkUsed: socialAuthMethod,
            });
        }
    }

    onHandleSuccess(user, callback);
};

/**
 * Segment tracking for authentication failure
 *
 * @param {object} param - top level param
 * @param {object} param.error - Error object
 * @param {Function} param.callback - Callback function passed into onHandleFailure
 * @param {boolean} param.registration - Is this registration or just a sign-in
 * @param {string} param.socialAuthMethod - type of social auth
 */
const onAuthenticationFailure = ({
    error,
    callback,
    registration = false,
    socialAuthMethod = null,
}) => {
    trackErrorMessageDisplayed({
        message: 'authentication error',
        action: `sign ${registration ? 'up' : 'in'}${
            socialAuthMethod ? `with ${socialAuthMethod}` : ''
        }`,
    });

    onHandleFailure(error, callback);
};

/**
 * Authentication Utils
 */
const AuthenticationUtils = {
    /**
     * Login function - a wrapper for spothero API
     *
     * @function logIn
     * @param {string} username  - user name of user
     * @param {string} password - password of user
     * @param {boolean | string} remember - remember me T/F (looking for truthy-ness)
     * @param {Function} onSuccess - Callback for Success
     * @param {Function} onFailure - Callback for Failure
     * @returns {void}
     */
    logIn(username, password, remember, onSuccess, onFailure) {
        AuthenticationAPI.login({
            username,
            password,
            remember_me: Boolean(remember), // eslint-disable-line camelcase
            mixpanel_id: SegmentUtils.getAnonymousId(), // eslint-disable-line camelcase
            include: UserUtils.INCLUDES,
        })
            .then(user => {
                onAuthenticationSuccess({
                    user,
                    callback: onSuccess,
                });
            })
            .catch(error => {
                onAuthenticationFailure({
                    error,
                    callback: onFailure,
                });
            });
    },

    /**
     * Gets the AUTH_TOKEN cookie for auth0 users
     *
     * @function getAuthTokenCookie
     * @returns {string | null} - The AUTH_TOKEN cookie
     */
    getAuthTokenCookie() {
        return StorageUtils.get(COOKIES.AUTH_TOKEN, 'cookie');
    },

    /**
     * Removes the AUTH_TOKEN cookie for auth0 users
     *
     * @function removeAuthTokenCookie
     * @returns {void}
     */
    removeAuthTokenCookie() {
        StorageUtils.remove(COOKIES.AUTH_TOKEN, 'cookie');
    },

    /**
     * Gets the sessionid cookie for monolith users
     *
     * @function getSessionIdCookie
     * @returns {string | null} - The sessionId cookie
     */
    getSessionIdCookie() {
        return StorageUtils.get(COOKIES.SESSION_ID, 'cookie');
    },

    /**
     * Removes the sessionid cookie for monolith users
     *
     * @function removeSessionIdCookie
     * @returns {void}
     */
    removeSessionIdCookie() {
        StorageUtils.remove(COOKIES.SESSION_ID, 'cookie');
        StorageUtils.remove(COOKIES.SESSION_UUID, 'cookie');
    },

    /**
     * Gets the Impersonation Token cookie for staff users who are impersonating a user
     *
     * @function getImpersonationTokenCookie
     * @returns {string | null} - The IMPERSONATE cookie
     */
    getImpersonationTokenCookie() {
        return StorageUtils.get(COOKIES.IMPERSONATE, 'cookie');
    },

    /**
     * Removes the Impersonation Token cookie for staff users who are impersonating a user
     *
     * @function removeImpersonationTokenCookie
     * @returns {void}
     */
    removeImpersonationTokenCookie() {
        StorageUtils.remove(COOKIES.IMPERSONATE, 'cookie');
    },

    /**
     * Sets the logout cookie that micro-frontends using the Auth0-Next api will use to clear the auth0 session
     *
     * @function setShLogoutCookie
     * @returns {void}
     */
    setShLogoutCookie() {
        StorageUtils.set(COOKIES.SH_LOGOUT, true, 'cookie');
    },

    /**
     * Removes the logout cookie that micro-frontends using the Auth0-Next api will use to clear the auth0 session
     *
     * @function removeShLogoutCookie
     * @returns {void}
     */
    removeShLogoutCookie() {
        StorageUtils.remove(COOKIES.SH_LOGOUT, 'cookie');
    },

    /**
     * Removes the guest cookie that micro-frontends using the Auth0-Next api use
     *
     * @function removeShGuestCookie
     * @returns {void}
     */
    removeShGuestCookie() {
        StorageUtils.remove(COOKIES.SH_GUEST, 'cookie');
    },

    /**
     * Adds and Removes relevant cookies when user logs out
     *
     * @function configureLogoutCookies
     * @returns {void}
     */
    configureLogoutCookies() {
        this.setShLogoutCookie();
        this.removeImpersonationTokenCookie();
        this.removeAuthTokenCookie();
        this.removeSessionIdCookie();
        UserUtils.identify(null);
    },

    /**
     * Adds and Removes relevant cookies when user logs in
     *
     * @function configureLoginCookies
     * @returns {void}
     */
    async configureLoginCookies() {
        this.removeShGuestCookie();
        this.removeShLogoutCookie();
        if (!Config.isDev && !this.getAuthTokenCookie()) {
            return await AuthenticationAPI.setCookie();
        }
    },
};

export default AuthenticationUtils;
