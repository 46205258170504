export const SEARCH_REQUEST_UPDATE_DATA = 'SEARCH_REQUEST_UPDATE_DATA';
export const SEARCH_REQUEST_SET_TERM = 'SEARCH_REQUEST_SET_TERM';
export const SEARCH_REQUEST_SET_LOCATION = 'SEARCH_REQUEST_SET_LOCATION';
export const SEARCH_REQUEST_UPDATE_TIMES = 'SEARCH_REQUEST_UPDATE_TIMES';
export const SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES = 'SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES';
export const updateData = (data) => {
    return {
        type: SEARCH_REQUEST_UPDATE_DATA,
        payload: data,
    };
};
export const setTerm = ({ term, city, destination }) => {
    const termLowerCase = term && term.toLowerCase();
    return {
        type: SEARCH_REQUEST_SET_TERM,
        payload: {
            transient: termLowerCase === 'daily' ||
                (termLowerCase !== 'monthly' &&
                    termLowerCase !== 'airport' &&
                    termLowerCase !== 'event' &&
                    termLowerCase === 'power_booking'),
            monthly: termLowerCase === 'monthly',
            airport: termLowerCase === 'airport',
            event: termLowerCase === 'event',
            powerBooking: termLowerCase === 'power_booking',
            city,
            destination,
        },
    };
};
export const updateTimes = ({ starts, ends, isMonthly = false, hideAirportDateTimeModal = false, }) => {
    return {
        type: SEARCH_REQUEST_UPDATE_TIMES,
        payload: {
            starts,
            ends: isMonthly ? null : ends,
            // If "filterSpot" in url, means we were filtering out "filterSpot" spot id after a no inventory error.
            // Strip from url on times change
            filterSpot: null,
            // Used to hide date time modal in airport search results
            hideAirportDateTimeModal,
        },
    };
};
export const setTermsAndUpdatePowerBookingTimes = ({ city, destination, powerBookingPeriods, powerBookingSource, }) => {
    return {
        type: SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES,
        payload: {
            city,
            destination,
            powerBookingPeriods,
            powerBookingSource,
        },
    };
};
