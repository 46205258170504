export const PRISMIC_CUSTOM_TYPE = Object.freeze({
    EVENT_PACKAGE_VENUE: 'consumer-single-event-package-venue',
    LEGAL: 'consumer-page-legal',
    FOOTER: 'footer',
    HEADER: 'header',
});

export const PRISMIC_SPAN_LINK_TYPE = Object.freeze({
    DOCUMENT: 'Document',
    MEDIA: 'Media',
    WEB: 'Web',
});

export const PRISMIC_SPAN_TYPE = Object.freeze({
    HYPERLINK: 'hyperlink',
});
