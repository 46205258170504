import trackWalkingTimeFiltered from 'segment/events/walking-time-filtered';
export const FILTERS_RESET = 'FILTERS_RESET';
export const FILTERS_UPDATE = 'FILTERS_UPDATE';
export const FILTER_SET_SHOW_TOTAL = 'SEARCH_SET_SHOW_TOTAL';
export const resetFilters = () => {
    return {
        type: FILTERS_RESET,
    };
};
export const updateFilters = (toUpdate) => {
    return {
        type: FILTERS_UPDATE,
        payload: toUpdate,
    };
};
export const setWalkingDistanceLimitInMinutesFilter = (walkingDistanceLimitInMinutes, isSegmentTrackingEnabled = true) => {
    return (dispatch) => {
        if (isSegmentTrackingEnabled) {
            trackWalkingTimeFiltered({
                walkingTime: walkingDistanceLimitInMinutes,
            });
        }
        dispatch({
            type: FILTERS_UPDATE,
            payload: { walkingDistanceLimitInMinutes },
        });
    };
};
/**
 * @description
 * This function toggles the showTotal which is used to ensure compliance with California Regulation that means we cant hide any fees upfront.
 * @example
 * dispatch(toggleShowTotal()); // sets stateBoolean to !stateBoolean
 */
export const toggleShowTotal = () => ({
    type: FILTER_SET_SHOW_TOTAL,
});
