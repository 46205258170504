import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetSelectedSpot } from 'store/spot/spot-actions';
import { userLogout } from 'store/user/user-actions';
import Navigation from '../../navigation';
import { Box } from '@spothero/ui';
import { headerStyles } from '../headerStyles';
import { expandedData, collapsedData } from '../constants';
import useConfig from 'hooks/use-config';
const HeaderMobile = () => {
    const { isProduction } = useConfig();
    const dispatch = useDispatch();
    const { data: user } = useSelector((state) => state.user);
    const clearSelectedSpot = () => {
        dispatch(resetSelectedSpot());
    };
    const onUserLogout = () => {
        dispatch(userLogout());
    };
    return (<Box {...headerStyles} as="header" role="banner" data-testid="Header">
            <Navigation isProduction={isProduction} user={user} collapsedData={collapsedData} expandedData={expandedData} onItemSelected={clearSelectedSpot} onUserLogout={onUserLogout}/>
        </Box>);
};
export default HeaderMobile;
