import { ROUTER_DATA_LOAD_FULFILLED } from '../router/router-actions';
export const initialState = () => ({ data: null });
export default function page(state = initialState(), { type, payload }) {
    switch (type) {
        case ROUTER_DATA_LOAD_FULFILLED: {
            return payload.cms || initialState();
        }
        default:
            return state;
    }
}
