import React from 'react';
import Prismic from 'prismic-javascript';
import COMPONENTS from 'cms/component-list';
export const CustomTypes = Object.freeze({
    EVENT_PACKAGE_VENUE: 'consumer-single-event-package-venue',
    HOME_PAGE: 'home-page',
    LEGAL: 'consumer-page-legal',
    FOOTER: 'footer',
    HEADER: 'header',
});
let client;
const CMSUtils = {
    init() {
        const apiEndpoint = 'https://spothero.prismic.io/api/v2';
        client = Prismic.client(apiEndpoint);
    },
    getPageData({ slug, customType, cookies, pageType }) {
        // if path has multiple parts, only use last part of path
        let cleanedSlug = slug;
        if (slug?.includes('/')) {
            const path = slug.split('/').filter(Boolean);
            cleanedSlug = path[path.length - 1];
        }
        let query = Prismic.Predicates.at(`my.${customType}.uid`, cleanedSlug);
        if (customType === CustomTypes.HOME_PAGE ||
            customType === CustomTypes.FOOTER) {
            query = Prismic.Predicates.at('document.type', customType);
        }
        // check for Prismic preview cookie to enable previews of unpublished Prismic content
        const prismicCookie = cookies?.['io.prismic.preview']
            ? JSON.parse(cookies['io.prismic.preview'])
            : null;
        const previewCookie = prismicCookie?.['spothero.prismic.io']?.preview || null;
        /* The below code uses query instead of the getByUID helper function so that we can include the preview cookie ref to support previews of unpublished content. Predicates can be used to query documents and custom types.
        https://prismic.io/docs/reactjs/query-the-api/how-to-query-the-api
        https://prismic.io/docs/reactjs/query-the-api/query-predicates-reference */
        return client
            .query(query, {
            ...(previewCookie && {
                ref: previewCookie,
            }),
        })
            .then(result => {
            if (!result?.results?.length) {
                // eslint-disable-next-line no-throw-literal
                throw {
                    status: 404,
                    message: `Page with slug ${slug} does not exist in Prismic data`,
                };
            }
            else {
                return {
                    pageType,
                    ...result.results[0].data,
                };
            }
        });
    },
};
/**
 * Matches up slices to the corresponding component and renders
 * in the order that Prismic provides. New slices need to be mapped
 * to the corresponding components inside cms/component-list
 *
 * @param {Array} slices - An array of Prismic Slice objects
 * @param {object} components Map of slice components
 * @returns {React.ReactNode}
 * The slice Component that corresponds to the `slice_type` of each
 * slice passed in from the slices array.
 */
export const formatSlices = ({ slices, components = COMPONENTS, ...rest }) => {
    return slices
        ? slices.map((slice, key) => {
            const Component = components[slice.slice_type];
            if (Component) {
                // Rest of props are passed to the Component to provide
                // access to params passed into formatSlices function
                return <Component key={key} slice={slice} {...rest}/>;
            }
            else {
                // eslint-disable-next-line no-console
                console.warn(`Error: slice_type of ${slice.slice_type} was not what was expected`);
                return null;
            }
        })
        : null;
};
export default CMSUtils;
