/* eslint-disable no-unused-vars */
import React from 'react';
import {Elements} from 'prismic-reactjs';

import {Image} from '@spothero/ui-backlog';
import {Box, Heading, Link, List, ListItem, Text} from '@spothero/ui';

/* -------------------------------------------------------------- *\
    More info about the HTML serializer function here:
    https://prismic.io/docs/technologies/html-serializer-reactjs

    NOTE: there is the potential to have the HTML Serializer
    handle embeds, inline images, and spans. That is not
    included here, but is documented in the above docs.
    However, the example Serializer in the documentation does
    not return React components.
/* -------------------------------------------------------------- */

// Function to add unique key to props
const propsWithUniqueKey = (props, key) => {
    return Object.assign(props || {}, {key});
};

/**
 * Helper function that can be used by our rich text serializers
 * Used to build images that can be hyperlinked, lazy-loaded, and
 * other future complexities.
 *
 * @function buildImage
 * @param {object} element - The provided element from Prismic, representing the image
 * @param {object} options - Options set by us, to apply to this image
 * @param {boolean} options.lazyLoadImages - Whether or not to lazyload images.
 * @returns {React.ReactNode}
 * The corresponding image component.
 */
export const buildImage = (element, {lazyLoadImages}) => {
    const imageElement = (
        <Image
            src={element.url}
            alt={element.alt}
            height={element.dimensions.height}
            width={element.dimensions.width}
            lazyLoad={lazyLoadImages}
            showLoader={false}
        />
    );

    if (element.linkTo) {
        return (
            <Link
                href={element.linkTo.url}
                isExternal={element.linkTo.target === '_blank'}
            >
                {imageElement}
            </Link>
        );
    } else {
        return imageElement;
    }
};

/**
 * A custom helper-function only used by Prismic's RichText component.
 * Takes in a variant name (as a string), and correctly maps the heading
 * element type provided by the JSON response returned from Prismic to the
 * corresponding fe-ui Heading component.
 *
 * @function headingSerializer
 * @param {string} variant -
 *  The required style variant string that will be applied to the heading component.
 *  List components are nested inside a parent Text component and inherit their
 *  styling from their parent container/component.
 *
 *  For a full list of variant names please see:
 *  - https://spothero.github.io/fe-ui/?path=/story/v2-heading--heading
 * @example
 *  <RichText
 *      render={prismic_JSON}
 *      htmlSerializer={headingSerializer('h2')}
 *  />
 * @param {object} options - additional styleProps options
 * @returns {React.ReactNode}
 * The Heading fe-ui component with the style variant applied.
 */

// Heading Serializer
export const headingSerializer = (variant, options = {}) => (
    type,
    element,
    content,
    children,
    key,
    props
) => {
    // additional options can be added and destructured before styleProps
    const {...styleProps} = options;

    switch (type) {
        case Elements.heading1: // Heading 1
            return (
                <Heading
                    as="h1"
                    variant={variant}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-h1"
                    margin={0}
                    {...styleProps}
                >
                    {children}
                </Heading>
            );

        case Elements.heading2: // Heading 2
            return (
                <Heading
                    as="h2"
                    variant={variant}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-h2"
                    margin={0}
                    {...styleProps}
                >
                    {children}
                </Heading>
            );

        case Elements.heading3: // Heading 3
            return (
                <Heading
                    as="h3"
                    variant={variant}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-h3"
                    margin={0}
                    {...styleProps}
                >
                    {children}
                </Heading>
            );

        case Elements.heading4: // Heading 4
            return (
                <Heading
                    as="h4"
                    variant={variant}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-h4"
                    margin={0}
                    {...styleProps}
                >
                    {children}
                </Heading>
            );

        case Elements.heading5: // Heading 5
            return (
                <Heading
                    as="h5"
                    variant={variant}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-h5"
                    margin={0}
                    {...styleProps}
                >
                    {children}
                </Heading>
            );

        case Elements.heading6: // Heading 6
            return (
                <Heading
                    as="h6"
                    variant={variant}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-h6"
                    margin={0}
                    {...styleProps}
                >
                    {children}
                </Heading>
            );

        default:
            // Always include a default that returns null
            return null;
    }
};

const getInternalLinkId = element => {
    const isInternalLink = element.spans?.some(
        s => s.data?.label === 'internal-link'
    );

    if (isInternalLink) {
        const span = element.spans.find(s => s.data?.label === 'internal-link');

        return element.text
            .substring(span.start, span.end)
            .replaceAll(' ', '-')
            .toLowerCase();
    }

    return null;
};

/**
 * A custom helper-function only used by Prismic's RichText
 * component. Used to correctly map the HTML element type
 * provided by the JSON response returned from Prismic to
 * the corresponding fe-ui Text component.
 *
 * @function bodySerializer
 * @param {object} options - Options that may be applied to some or all components
 * @param {boolean} [lazyLoadImages=true] - Whether or not to lazyload images.
 * Our lazyloading mechanic is triggered via scrolling, however in some contexts
 * (for instance, opening and closing a panel) we may want an image to appear
 * without additional scrolling to occur.
 * @param {any} [paddingBottom={base: 4, tablet: 6}] - Padding control over block-
 * level elements.
 * @param {string} [linkVariant='standard'] - changes color of hyperlinks dependant
 * on slice theme.
 * @example
 *  <RichText
 *      render={prismic_JSON}
 *      htmlSerializer={bodySerializer()}
 *  />
 * @returns {React.ReactNode}
 * The corresponding fe-ui component.
 */

// Body Serializer
export const bodySerializer = (options = {}) => (
    type,
    element,
    content,
    children,
    key,
    props
) => {
    const {
        lazyLoadImages = true,
        paddingBottom = {base: 4},
        linkVariant = 'standard',
    } = options;

    switch (type) {
        /* -------------------------------------------------------------- *\
            Added in headings into `bodySerializer` to handle heading
            levels 3-6 passed into the serializer, specifically in the
            case of the `RichTextWithTitle` component.
        /* -------------------------------------------------------------- */
        case Elements.heading3: // Heading 3
            return (
                <Heading
                    as="h3"
                    id={getInternalLinkId(element)}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-h3"
                    margin={0}
                    paddingBottom={paddingBottom}
                >
                    {children}
                </Heading>
            );

        case Elements.heading4: // Heading 4
            return (
                <Heading
                    as="h4"
                    id={getInternalLinkId(element)}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-h4"
                    margin={0}
                    paddingBottom={paddingBottom}
                >
                    {children}
                </Heading>
            );

        case Elements.heading5: // Heading 5
            return (
                <Heading
                    as="h5"
                    id={getInternalLinkId(element)}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-h5"
                    margin={0}
                    paddingBottom={paddingBottom}
                >
                    {children}
                </Heading>
            );

        case Elements.heading6: // Heading 6
            return (
                <Heading
                    as="h6"
                    id={getInternalLinkId(element)}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-h6"
                    margin={0}
                    paddingBottom={paddingBottom}
                >
                    {children}
                </Heading>
            );

        case Elements.paragraph: // Paragraph
            return (
                <Text
                    as={type.replace('paragraph', 'p')}
                    id={getInternalLinkId(element)}
                    variant="body"
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-p"
                    paddingBottom={paddingBottom}
                >
                    {children}
                </Text>
            );

        /* -------------------------------------------------------------- *\
        TODO: When a slice needs Preformatted html this is the
        expected approach. Commenting out for coverage for now.
        case Elements.preformatted: // Preformatted
            return (
                <Text
                    as={type.replace('preformatted', 'pre')}
                    variant={variant}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-pre"
                >
                    {children}
                </Text>
            );
        /* -------------------------------------------------------------- */

        case Elements.strong: // Strong
            return (
                <Text
                    as={type}
                    id={getInternalLinkId(element)}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-strong"
                >
                    {children}
                </Text>
            );

        case Elements.em: // Emphasis
            return (
                <Text
                    as={type}
                    variant="body"
                    id={getInternalLinkId(element)}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-em"
                >
                    {children}
                </Text>
            );

        case Elements.hyperlink:
            return (
                <Link
                    key={key}
                    href={element.data.url}
                    isExternal={element.data.target === '_blank'}
                    {...propsWithUniqueKey(props, key)}
                    data-testid="htmlSerializer-hyperlink"
                    variant={linkVariant}
                    textDecoration="underline"
                >
                    {children}
                </Link>
            );

        case Elements.list: // Unordered List
            /* -------------------------------------------------------------- *\
                Chakra Box components do not accept variants, so
                the Text components extends the Box component and
                accepts variants. The List Component, does not accept
                Text variants but inherits the styles from the parent
                container. This is intentional.
                TODO: Should Text's `variant` property be accepted
                on all text-base ui components?
            /* -------------------------------------------------------------- */
            return (
                <Text
                    as="div"
                    id={getInternalLinkId(element)}
                    variant="body"
                    paddingBottom={paddingBottom}
                    key={key}
                    data-testid="htmlSerializer-ul-container"
                >
                    <List
                        type="unordered"
                        data-testid="htmlSerializer-ul"
                        stylePosition="outside"
                        marginLeft={8}
                    >
                        {children}
                    </List>
                </Text>
            );

        case Elements.listItem: // Unordered List Item
            return (
                <ListItem
                    id={getInternalLinkId(element)}
                    key={key}
                    data-testid="htmlSerializer-ulItem"
                    paddingLeft={2}
                >
                    {children}
                </ListItem>
            );

        case Elements.oList: // Ordered List
            return (
                <Text
                    as="div"
                    id={getInternalLinkId(element)}
                    variant="body"
                    paddingBottom={paddingBottom}
                    key={key}
                    data-testid="htmlSerializer-ol-container"
                >
                    <List
                        type="ordered"
                        data-testid="htmlSerializer-ol"
                        stylePosition="outside"
                        marginLeft={8}
                        paddingLeft={2}
                    >
                        {children}
                    </List>
                </Text>
            );

        case Elements.oListItem: // Ordered List Item
            return (
                <ListItem
                    id={getInternalLinkId(element)}
                    key={key}
                    data-testid="htmlSerializer-olItem"
                    paddingLeft={2}
                >
                    {children}
                </ListItem>
            );

        // Image
        case Elements.image: // Image
            return (
                <Box
                    as="div"
                    id={getInternalLinkId(element)}
                    key={key}
                    d="flex"
                    justifyContent="center"
                    paddingBottom={paddingBottom}
                    data-testid="htmlSerializer-image"
                    role="img"
                >
                    {buildImage(element, {lazyLoadImages})}
                </Box>
            );

        default:
            // Always include a default that returns null
            return null;
    }
};

/**
 * A custom helper-function used to protect Prismic's RichText
 * component from receiving an empty JSON response – and thereby
 * avoiding rendering empty HTML tags on the page.
 *
 * @function ignoreEmptyRichTextObjects
 * @param {Array} richTextObjectArray -
 * The JSON response from Prismic.
 * @example
 *  <RichText
 *      render={ignoreEmptyRichTextObjects(richTextObjectArray)}
 *      htmlSerializer={htmlSerializer('body')}
 *  />
 * @returns {Array} A filtered array of rich text objects
 * excluding ones with empty text fields.
 */

// ignoreEmptyRichTextObjects checker function
export const ignoreEmptyRichTextObjects = richTextObjectArray => {
    return richTextObjectArray.filter(item => item.text || item.url);
};
