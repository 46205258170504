import {Page} from 'utils/page-utils';
import {getVenue} from 'api/event-packages';
import CMSUtils from 'utils/cms';
import {PRISMIC_CUSTOM_TYPE} from 'utils/prismic-types';

const getInitialState = async ({
    cookies,
    state,
    route: {
        location: {search},
    },
}) => {
    // get prismic data for the event package venue template
    // note that every venue gets the same data (e.g. generic event package FAQs) and there is no prismic data specific to a certain venue
    const prismicData = await CMSUtils.getPageData({
        cookies,
        slug: 'event-package-venue',
        customType: PRISMIC_CUSTOM_TYPE.EVENT_PACKAGE_VENUE,
    });
    // get venue ID from URL
    const urlParams = new URLSearchParams(search);
    const venueId = urlParams.get('id');
    // use venue ID to find event packages at this venue
    const venueData = await getVenue(venueId);

    state.searchRequest.pageType = Page.EVENT_PACKAGES_VENUE;

    if (!venueId || !venueData?.destination?.title) {
        // eslint-disable-next-line no-throw-literal
        throw {
            status: 404,
            message: `This venue does not exist or has incomplete data`,
        };
    } else {
        return {
            ...state,
            page: {
                cmsData: prismicData,
                venue: {...venueData},
            },
        };
    }
};

export default getInitialState;
