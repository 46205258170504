export const headerStyles = {
    position: { base: 'fixed', tablet: 'absolute', desktop: 'relative' },
    left: { base: '0', desktop: 'unset' },
    top: { base: '0', desktop: 'unset' },
    width: '100%',
    height: { base: '52px', tablet: '64px' },
    zIndex: { base: 'layer4', desktop: 'layer3' },
    textAlign: 'center',
    transition: 'left .2s cubic-bezier(.25,.46,.45,.94)',
    backgroundColor: 'primary.default',
    paddingRight: { base: 0, tablet: '20px' },
    boxSizing: 'border-box',
};
