import React from 'react';
import { Button } from '@spothero/ui';
export const FindParkingSpotsButton = ({ label = 'Find Parking Spots', onClick: onClickCallback, }) => {
    const handleClick = () => {
        console.log('FindParkingSpotsButton clicked');
        if (onClickCallback) {
            onClickCallback();
        }
    };
    return (<Button width="100%" data-testid="FindParkingSpotsButton-button" onClick={handleClick}>
            {label}
        </Button>);
};
