import { Box, Flex } from '@spothero/ui';
import React from 'react';
import { SearchComponent, SearchComponentProvider, } from '@spothero/search-component';
import { useGoogleMapsLibraries } from 'common/google-map-context';
import { PoweredByGoogle } from '@spothero/ui-backlog';
import useConfig from 'hooks/use-config';
import { FindParkingSpotsButton } from './FindParkingSpotsButton';
export const SearchComponentContainer = () => {
    // eslint-disable-next-line no-empty-function
    const handler = () => { };
    const { googleMapsLoaded, googleMapsLibraries } = useGoogleMapsLibraries();
    const { siteUrl } = useConfig();
    if (!googleMapsLoaded) {
        return null;
    }
    return (<Flex padding={4} justifyContent="center">
            <Box minWidth={{ mobileXL: 96 }} flexGrow={1}>
                <SearchComponentProvider value={{
            siteUrl,
            eventDestinationsHash: {},
            locationBias: {},
            airports: [],
            searchEvents: [],
            googleMapsLibraries,
            PoweredByGoogle,
            FindParkingSpotsButton,
            handleOnChangeForEvents: handler,
            trackEventListItemSelected: handler,
            trackSearchSuggestionSelected: handler,
            trackDateEntered: handler,
            trackErrorMessageDisplayed: handler,
            trackFindParkingNearMeClicked: handler,
        }}>
                    <SearchComponent geolocationBias={{}} parkingType="hourly" parkingSubType="hourly" timezone={undefined} locationId={undefined} locationKind={undefined} trackPowerBookingOpened={handler} trackSwitchedVerticalTabs={handler}/>
                </SearchComponentProvider>
            </Box>
        </Flex>);
};
