import { APIRegistry } from '@spothero/utils/api';
import StorageUtils from '@spothero/utils/storage';
import isNil from 'lodash/isNil';
import Config from '@/config/index';
import BranchUtils from 'utils/branch';
import CMSUtils from 'utils/cms';
import GTMUtils from 'utils/gtm';
import loadThirdPartyScripts from 'utils/third-party';
import SegmentUtils from 'utils/segment';
import UserUtils from 'utils/user-utils';
import { Dedupe as DedupeIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/browser';
import { getWebExperimentCookie } from 'utils/web-experiment';
/**
 * Startup function - runs at start of app on the server-side primarily to instantiate:
 * APIRegistry(s), Sentry, Branch, Session Storage, CMS Utils, GTM Utils
 *
 * @param {object} param - Top level param
 * @param {ConfigData} param.configData - config data setup with secrets
 * @param {boolean} param.isBrowser - Access to browser apis (ie window)
 * @param {User} param.user - User data
 */
const startup = async ({ configData, isBrowser, user }) => {
    Config.set(configData);
    /* V2-CLEANUP
    Remove this ratesSearch APIRegistry, and confirm that nothing else is relying on it.
    This is used on all Search, Spot Details, and Checkout for all verticals.
    */
    if (!APIRegistry.get('ratesSearch')) {
        const searchAPIUtils = APIRegistry.create({
            name: 'ratesSearch',
            config: {
                baseUrl: Config.spotHeroAPIBasePath,
                additionalHeaders: {
                    'x-spothero-spa': 'consumer-web',
                    'SpotHero-Version': '2019-08-13',
                },
            },
        });
        searchAPIUtils.addRetry({
            retries: 10,
        });
    }
    if (!APIRegistry.get('craig')) {
        APIRegistry.create({
            name: 'craig',
            config: {
                baseUrl: Config.craigUrl,
            },
        });
    }
    if (!APIRegistry.get('craigSearch')) {
        const craigAPIUtils = APIRegistry.create({
            name: 'craigSearch',
            config: {
                baseUrl: Config.craigUrl,
            },
        });
        craigAPIUtils.addRetry({
            retries: 10,
        });
    }
    if (!APIRegistry.get('auth')) {
        APIRegistry.create({
            name: 'auth',
            config: {
                baseUrl: Config.siteUrl,
            },
        });
    }
    if (isBrowser) {
        if (Config.isDeployed) {
            Sentry.init({
                dsn: Config.sentryApiPublicKey,
                release: Config.release,
                environment: Config.isProduction ? 'production' : 'staging',
                // @ts-ignore
                sendDefaultPii: true,
                attachStacktrace: true,
                integrations: [new DedupeIntegration()],
                allowUrls: ['spothero.com', 'kickthe.tires'],
                normalizeDepth: 10,
                ignoreErrors: [
                    /Attempted to assign to readonly property/,
                    'Non-Error promise rejection captured',
                    'Object Not Found Matching Id',
                    /Object Not Found Matching Id/,
                    /Request aborted/,
                    /Non-Error promise rejection captured/,
                    /(chunkloaderror:\s)?loading.+\d\sfailed.?/i,
                    /@context/,
                    'Cannot redefine property: googletag',
                    'Network Error', // Should remove this eventually but putting here for now post sentry upgrade
                ],
            });
        }
        if (Config.branchIOApiKey) {
            BranchUtils.init(Config.branchIOApiKey);
        }
        // remove the time to first result session stored so it can be re-created on search
        StorageUtils.remove('sh-ttfr-tracked', 'session');
        CMSUtils.init();
        /* eslint-disable no-undefined */
        GTMUtils.push({
            // @ts-ignore
            client: Config.isMobile ? 'mobile' : 'desktop',
            dateInt: `${new Date().getTime()}`,
            facebookLogoutUrl: '/logout',
            userId: user?.id,
            facebookOAuth: undefined,
            facebookAppId: Config.facebookAppId,
            facebookChannelUrl: Config.facebookChannelUrl,
        });
        /* eslint-enable no-undefined */
        // TODO Incorporate above 3rd party inits into this
        loadThirdPartyScripts();
        if (Config.isSegmentEnabled) {
            SegmentUtils.init({
                apiKey: Config.isDeployed ? Config.segmentApiKey : 'debug',
                debug: !Config.isDeployed,
            });
            // Wait for Segment to load before activating Optimizely experiments
            SegmentUtils.waitForSegmentThenLoad(5000, () => {
                // After GTM, Sentry (browser), and Segment have been initialized,
                // call their respective identify functions.
                // This is wrapped in a check, as calling identify with no user is equivalent to logging out.
                if (!isNil(user)) {
                    UserUtils.identify(user);
                }
            });
            getWebExperimentCookie();
        }
    }
};
export default startup;
/**
 * @typedef ConfigData
 * @property {string} spotHeroAPIBasePath - API base path
 * @property {string} siteUrl - Site url
 * @property {string} spotHeroAPIHostInternal - Internal API host
 * @property {string} distanceAPIUrl - URL for distance service
 * @property {string} craigUrl - URL for craig
 * @property {string} auth0Domain - Auth0 domain
 * @property {string} auth0ClientID - Auth0 client id
 * @property {string} auth0AudienceV1API - Auth0 Audience API
 * @property {boolean} isDev - Is development
 * @property {boolean} isDeployed - Is deployed
 * @property {boolean} isProduction - Is production
 * @property {string} release - Release version
 * @property {string} branchIOApiKey - Branch API key
 * @property {string} sentryApiPublicKey - Sentry API key
 * @property {{active: boolean, creditPerReferral: any, creditForReferee: any}} referralCreditOverride -
 * @property {boolean} runningTests - is this running tests
 * @property {boolean} mixpanelEnabled - is mixpanel enabled
 * @property {boolean} mixpanelDebug - is mixpanel in debug mode
 * @property {string} mixpanelToken - Mixpanel token
 * @property {string} googleMapsApiKey - Google Maps Key
 * @property {string} recaptchaSitekey - Recaptcha Key
 * @property {string} stripeV2JsPath - Stripe API path
 * @property {string} stripeV3JsPath - Stripe API path
 * @property {string} stripePublicApiKey - Stripe Key
 * @property {string} braintreeTokenizationKey - Braintree Key
 * @property {boolean} isPaypalEnabled - Is Paypal enabled
 * @property {string} paypalClientId - Paypal Client ID
 * @property {string} paypalClientIdUsd - Paypal Client ID USD
 * @property {string} paypalClientIdCad - Paypal Client ID CAD
 * @property {string} facebookAppId - Facebook App ID
 * @property {string} facebookChannelUrl - Facebook ChannelURL
 * @property {string} promoInvitePromocode - Promo Invite Code
 * @property {string} promoInviteDescription - Promo Description
 * @property {string} promoInviteMetaTitle - Promo Meta title
 * @property {string} promoInviteMetaDescription - Promo Meta Description
 * @property {string} parkingNearMeTitle - Parking Near me Title
 * @property {string} parkingNearMeDescription - Parking Near me Description
 * @property {string} cloudinaryCloudName - Cloudinary name
 * @property {boolean} showAppInterstitial - Show app interstitial for users on mobile with app downloaded
 * @property {string} clientIPAddress - IP of client
 * @property {number} mobilePaginationLimit - Pagination limit on mobile
 * @property {string} referralIncentiveMessage - referral incentive message
 * @property {boolean} isSegmentEnabled - is segment enabled
 * @property {string} segmentApiKey - Segment API key
 * @property {Array<string>} citySlugs - Array of cities we operate in
 * @property {string} gtmAccount - Google tag manager account
 * @property {boolean} isMobile - is client mobile
 */
/**
 * @typedef ServerCookies - Cookies that come down from server
 * @property {string} sh-experiment-id - Experiment id
 * @property {ShExperimentVariations} sh-experiment-variations - Variations of experiment
 */
/**
 * @typedef User
 * @property {Array<any>} creditCards - Array of credit cards
 * @property {any | null} paypalCard - Can be null but also paypal data
 * @property {object} creditWallets - object of credit wallets
 * @property {string | null} dateJoined - Date user joined, null if not populated
 * @property {string | null} displayName - User Display name, null if not populated
 * @property {string | null} email - User email, null if not populated
 * @property {object} experiments - Experiments User is viewing
 * @property {string | null} firstName - User first name, null if not populated
 * @property {string | null} id - User ID, null if not populated
 * @property {boolean} isAdmin - is User Admin
 * @property {boolean} isSeller - is User Seller
 * @property {boolean} justCreated - is User justCreated
 * @property {string | null} lastName - User last name, null if not populated
 * @property {Array<any>} licensePlates - LicensePlates saved by user
 * @property {string | null} phoneNumber - User phone number, null if not populated
 * @property {string | null} referralToken - User referral Token, null if not populated
 * @property {Array<any>} vehicles - Vehicles on user's account
 * @property {("logged-out" | "logged-in" | any)} status - Status (MR Improvised these off what I saw so its kind of like jazz)
 */
