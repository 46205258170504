export const THEME_VARIABLES = {
    'Light - Primary': {
        textColor: 'black',
        backgroundColor: 'white',
        primaryButtonVariant: 'primary',
        secondaryButtonVariant: 'secondary',
        primaryHeadingTextColor: 'gray.dark',
        secondaryHeadingTextColor: 'black',
        tableVariant: 'roundedGray',
        linkVariant: 'standard',
    },
    'Light - Secondary': {
        textColor: 'black',
        backgroundColor: 'gray.light',
        primaryButtonVariant: 'primary',
        secondaryButtonVariant: 'secondary',
        primaryHeadingTextColor: 'gray.dark',
        secondaryHeadingTextColor: 'black',
        tableVariant: 'rounded',
        linkVariant: 'standard',
    },
    'Dark - Primary': {
        textColor: 'white',
        backgroundColor: 'primary.default',
        primaryButtonVariant: 'primaryOnDark',
        secondaryButtonVariant: 'secondaryOnDark',
        primaryHeadingTextColor: 'white',
        secondaryHeadingTextColor: 'white',
        linkVariant: 'onDark',
    },
    'Dark - Secondary': {
        textColor: 'white',
        backgroundColor: 'secondary.default',
        primaryButtonVariant: 'primaryOnDark',
        secondaryButtonVariant: 'secondaryOnDark',
        primaryHeadingTextColor: 'white',
        secondaryHeadingTextColor: 'white',
        linkVariant: 'onDark',
    },
    'Dark - Tertiary': {
        textColor: 'white',
        backgroundColor: 'black',
        primaryButtonVariant: 'primaryOnDark',
        secondaryButtonVariant: 'secondaryOnDark',
        primaryHeadingTextColor: 'white',
        secondaryHeadingTextColor: 'white',
        linkVariant: 'onDark',
    },
};

export const getSliceThemeVariables = theme => {
    return THEME_VARIABLES[theme] || THEME_VARIABLES['Light - Primary'];
};
