import dayjs from 'utils/dayjs';
import Config from '@/config/index';
import {v4 as uuidV4} from 'uuid';
import ErrorUtils from 'utils/error-utils';
import SearchTracking, {SEARCH_TRACKING_ACTIONS} from 'utils/search-tracking';
import {
    getMonthlyFacilities,
    getMonthlyFacilitiesLoadMoreSpots,
} from 'api/search-v2/monthly/facilities/getMonthlyFacilities';
import {
    getTransientFacilities,
    getTransientFacilitiesLoadMoreSpots,
} from 'api/search-v2/transient/facilities/getTransientFacilities';
import {
    getBulkSearchTransientFacilities,
    getBulkSearchTransientFacilitiesLoadMoreSpots,
} from 'api/search-v2/power-booking/facilities/getBulkSearchTransientFacilities';
import {getAirportFacilities} from 'api/search-v2/airport/spot/getAirportFacilities';
import {getSearchLimits} from 'utils/search-limits';
import {notify} from '../notification/notification-actions';
import trackSearchClickedLoadMoreSpots from 'segment/events/search-clicked-load-more-spots';
import {getParkingType, getSearchVehicle} from 'store/selectors/selectors';
import trackErrorMessageDisplayed from 'segment/events/error-message-displayed';
import {isMoreThan24Hours} from 'utils/time';
import {getWebExperimentCookie} from 'utils/web-experiment';
import {isOversizedFeatureEnabled} from 'utils/search-vehicle';

export const SPOTS_GET_TRANSIENT_V2 = 'SPOTS_GET_TRANSIENT_V2';
export const SPOTS_GET_MONTHLY_V2 = 'SPOTS_GET_MONTHLY_V2';
export const SPOTS_GET_AIRPORT_V2 = 'SPOTS_GET_AIRPORT_V2';
export const SPOTS_GET_TRAVEL_DISTANCES_V2 = 'SPOTS_GET_TRAVEL_DISTANCES_V2';
export const SPOTS_GET_BULK_TRANSIENT_V2 = 'SPOTS_GET_BULK_TRANSIENT_V2';

function handleError(dispatch, error) {
    trackErrorMessageDisplayed({
        message: 'Error fetching spots',
        screen: 'search',
        action: 'fetching spots',
    });
    ErrorUtils.sendSentryException(error);

    dispatch(
        notify({
            id: uuidV4(),
            type: 'error',
            message: 'Error fetching spots',
        })
    );

    return {
        error: error?.data,
    };
}

export const loadSpotsTransientV2 = ({
    searchTrackingAction,
    locationContext,
    mapCenter,
    mapBounds,
    eventId,
}) => {
    return (dispatch, getState) => {
        const prevState = getState();
        const parkingType = getParkingType(prevState);
        const userVehicle = getSearchVehicle(prevState);
        const isOversizedEnabled = isOversizedFeatureEnabled(parkingType);
        const searchRequestState = prevState.searchRequest;
        const vehicleInfoId = userVehicle?.id;
        const {
            latitude,
            longitude,
            starts,
            ends,
            operator_id: operatorId,
            rebook_reservation_id: rebookId,
            filterSpot,
        } = searchRequestState;
        const userState = prevState.user;
        const {
            data: {isAdmin},
        } = userState;
        const timezone = prevState.city.data.timezone;
        const fingerprint = getWebExperimentCookie();

        SearchTracking.updateAction(searchTrackingAction);
        const {
            sessionUUID,
            searchUUID,
            actionUUID,
            action,
        } = SearchTracking.getValues();

        const isSearchDurationMoreThan24Hours = isMoreThan24Hours({
            starts,
            ends,
        });

        const {resultLimit, maxDistance} = getSearchLimits({
            locationContext,
            mapCenter,
            mapBounds,
            isSearchDurationMoreThan24Hours,
        });

        /* eslint-disable camelcase */
        return dispatch({
            type: SPOTS_GET_TRANSIENT_V2,
            payload: getTransientFacilities(
                {
                    ...(mapCenter
                        ? {
                              origin_lat: latitude,
                              origin_lon: longitude,
                              lat: mapCenter.latitude,
                              lon: mapCenter.longitude,
                          }
                        : {
                              lat: latitude,
                              lon: longitude,
                          }),
                    starts: dayjs(starts).format(Config.craigApiDateTimeFormat),
                    ends: dayjs(ends).format(Config.craigApiDateTimeFormat),
                    show_unavailable: isAdmin,
                    page_size: resultLimit,
                    max_distance_meters: maxDistance,
                    operator_id: operatorId,
                    session_id: sessionUUID,
                    search_id: searchUUID,
                    action_id: actionUUID,
                    action,
                    fingerprint,
                    // Include vehicle info id only for transient parking type
                    ...(vehicleInfoId && isOversizedEnabled
                        ? {vehicle_info_id: vehicleInfoId}
                        : {}),
                },
                {
                    eventId,
                    timezone,
                    rebookId,
                    filterSpot,
                }
            ),
        }).catch(error => handleError(dispatch, error));
        /* eslint-enable camelcase */
    };
};

export const loadMoreSpotsTransientV2 = () => {
    return (dispatch, getState) => {
        const prevState = getState();
        const loadMoreSpotsUrl = prevState.spots.loadMoreSpotsUrl;
        const parkingType = getParkingType(prevState);

        // empty loadMoreSpotsUrl (@next) indicates no more spots are available from api
        if (!loadMoreSpotsUrl) {
            return;
        }

        SearchTracking.updateAction(SEARCH_TRACKING_ACTIONS.LIST_SEARCH_NEXT);
        trackSearchClickedLoadMoreSpots({parkingType});

        return dispatch({
            type: SPOTS_GET_TRANSIENT_V2,
            payload: getTransientFacilitiesLoadMoreSpots({
                // Below includes walking distance until BE includes it in @next
                loadMoreSpotsUrl: `${loadMoreSpotsUrl}&include_walking_distance=true`,
            })
                .then(response => ({
                    loadMoreSpotsUrl: response.loadMoreSpotsUrl,
                    results: [...prevState.spots.data, ...response.results], // append to existing spots
                }))
                .catch(error => handleError(dispatch, error)),
        });
    };
};

export const loadSpotsMonthlyV2 = ({
    searchTrackingAction,
    locationContext = undefined,
    mapCenter = undefined,
    mapBounds = undefined,
}) => {
    return (dispatch, getState) => {
        const prevState = getState();
        const searchRequestState = prevState.searchRequest;
        const {
            latitude,
            longitude,
            starts,
            operator_id: operatorId,
            rebook_reservation_id: rebookId,
            filterSpot,
        } = searchRequestState;
        const userState = prevState.user;
        const {
            data: {isAdmin},
        } = userState;
        const timezone = prevState.city.data.timezone;
        const fingerprint = getWebExperimentCookie();

        SearchTracking.updateAction(searchTrackingAction);
        const {
            sessionUUID,
            searchUUID,
            actionUUID,
            action,
        } = SearchTracking.getValues();

        const {resultLimit, maxDistance} = getSearchLimits({
            locationContext,
            mapCenter,
            mapBounds,
        });

        /* eslint-disable camelcase */
        return dispatch({
            type: SPOTS_GET_MONTHLY_V2,
            payload: getMonthlyFacilities(
                {
                    ...(mapCenter
                        ? {
                              origin_lat: latitude,
                              origin_lon: longitude,
                              lat: mapCenter.latitude,
                              lon: mapCenter.longitude,
                          }
                        : {
                              lat: latitude,
                              lon: longitude,
                          }),
                    starts: dayjs(starts).format(Config.craigApiDateTimeFormat),
                    show_unavailable: isAdmin,
                    page_size: resultLimit,
                    max_distance_meters: maxDistance,
                    operator_id: operatorId,
                    session_id: sessionUUID,
                    search_id: searchUUID,
                    action_id: actionUUID,
                    action,
                    fingerprint,
                },
                {
                    timezone,
                    rebookId,
                    filterSpot,
                }
            ),
        }).catch(error => handleError(dispatch, error));
        /* eslint-enable camelcase */
    };
};

export const loadMoreSpotsMonthlyV2 = () => {
    return (dispatch, getState) => {
        const prevState = getState();
        const loadMoreSpotsUrl = prevState.spots.loadMoreSpotsUrl;
        const parkingType = getParkingType(prevState);

        // empty loadMoreSpotsUrl (@next) indicates no more spots are available from api
        if (!loadMoreSpotsUrl) {
            return;
        }

        SearchTracking.updateAction(SEARCH_TRACKING_ACTIONS.LIST_SEARCH_NEXT);
        trackSearchClickedLoadMoreSpots({parkingType});

        return dispatch({
            type: SPOTS_GET_MONTHLY_V2,
            payload: getMonthlyFacilitiesLoadMoreSpots({
                // Below includes walking distance until BE includes it in @next
                loadMoreSpotsUrl: `${loadMoreSpotsUrl}&include_walking_distance=true`,
            })
                .then(response => ({
                    loadMoreSpotsUrl: response.loadMoreSpotsUrl,
                    results: [...prevState.spots.data, ...response.results], // append to existing spots
                }))
                .catch(error => handleError(dispatch, error)),
        });
    };
};

export const loadSpotsAirportV2 = () => {
    return (dispatch, getState) => {
        const prevState = getState();
        const searchRequestState = prevState.searchRequest;
        const {
            starts,
            ends,
            operator_id: operatorId,
            rebook_reservation_id: rebookId,
            filterSpot,
        } = searchRequestState;
        const destination = prevState.destination.data;
        const iataCode = destination.airport.iata_code;
        const userState = prevState.user;
        const {
            data: {isAdmin},
        } = userState;
        const timezone = prevState.city.data.timezone;
        const fingerprint = getWebExperimentCookie();

        SearchTracking.updateAction(SEARCH_TRACKING_ACTIONS.SEARCH);
        const {
            sessionUUID,
            searchUUID,
            actionUUID,
            action,
        } = SearchTracking.getValues();

        /* eslint-disable camelcase */
        return dispatch({
            type: SPOTS_GET_AIRPORT_V2,
            payload: getAirportFacilities(
                {
                    iata: iataCode,
                    starts: dayjs(starts).format(Config.craigApiDateTimeFormat),
                    ends: dayjs(ends).format(Config.craigApiDateTimeFormat),
                    show_unavailable: isAdmin,
                    operator_id: operatorId,
                    session_uuid: sessionUUID,
                    search_id: searchUUID,
                    action_id: actionUUID,
                    action,
                    fingerprint,
                },
                {
                    userRequestedStarts: dayjs(starts).format(
                        Config.craigApiDateTimeFormat
                    ),
                    userRequestedEnds: dayjs(ends).format(
                        Config.craigApiDateTimeFormat
                    ),
                    timezone,
                    rebookId,
                    filterSpot,
                }
            ).then(response => {
                return {
                    results: response,
                };
            }),
        }).catch(error => handleError(dispatch, error));
        /* eslint-enable camelcase */
    };
};

export const loadBulkSearchSpotsTransientV2 = ({
    searchTrackingAction,
    locationContext = undefined,
    mapCenter = undefined,
    mapBounds = undefined,
    eventId = undefined,
}) => {
    return (dispatch, getState) => {
        const prevState = getState();
        const searchRequestState = prevState.searchRequest;
        const {
            latitude,
            longitude,
            powerBookingPeriods,
            operator_id: operatorId,
            rebook_reservation_id: rebookId,
            filterSpot,
            powerBookingSource,
        } = searchRequestState;
        const userState = prevState.user;
        const {
            data: {isAdmin},
        } = userState;
        const timezone = prevState.city.data.timezone;
        const fingerprint = getWebExperimentCookie();

        SearchTracking.updateAction(searchTrackingAction);
        const {
            sessionUUID,
            searchUUID,
            actionUUID,
            action,
        } = SearchTracking.getValues();

        const {resultLimit, maxDistance} = getSearchLimits({
            locationContext,
            mapCenter,
            mapBounds,
            isSearchDurationMoreThan24Hours: false,
        });

        /* eslint-disable camelcase */

        const params = {
            session_id: sessionUUID,
            search_id: searchUUID,
            action_id: actionUUID,
            fingerprint,
            action,
            ...(mapCenter
                ? {
                      origin_lat: latitude,
                      origin_lon: longitude,
                      lat: mapCenter.latitude,
                      lon: mapCenter.longitude,
                  }
                : {
                      lat: latitude,
                      lon: longitude,
                  }),
        };

        const requestData = {
            periods: powerBookingPeriods,
            oversize: false,
            show_unavailable: isAdmin,
            sort_by: 'relevance',
            include_walking_distance: true,
            max_distance_meters: maxDistance,
            page_size: resultLimit,
            operator_id: operatorId,
            /* eslint-enable camelcase */
        };

        const formatOptions = {
            eventId,
            timezone,
            rebookId,
            filterSpot,
            powerBookingSource,
        };

        return dispatch({
            type: SPOTS_GET_BULK_TRANSIENT_V2,
            payload: getBulkSearchTransientFacilities(
                requestData,
                params,
                formatOptions
            ),
        }).catch(error => handleError(dispatch, error));
    };
};

export const loadMoreBulkSearchSpotsTransientV2 = ({locationContext}) => {
    return (dispatch, getState) => {
        const prevState = getState();
        const loadMoreSpotsUrl = prevState.spots.loadMoreSpotsUrl;
        const parkingType = getParkingType(prevState);
        const searchRequestState = prevState.searchRequest;

        // empty loadMoreSpotsUrl (@next) indicates no more spots are available from api
        if (!loadMoreSpotsUrl) {
            return;
        }

        const {
            powerBookingPeriods,
            operator_id: operatorId,
            powerBookingSource,
        } = searchRequestState;
        const userState = prevState.user;
        const {
            data: {isAdmin},
        } = userState;

        const {resultLimit, maxDistance} = getSearchLimits({
            locationContext,
            isSearchDurationMoreThan24Hours: false,
        });

        /* eslint-disable camelcase */
        const requestData = {
            periods: powerBookingPeriods,
            oversize: false,
            show_unavailable: isAdmin,
            sort_by: 'relevance',
            include_walking_distance: true,
            page_size: resultLimit,
            operator_id: operatorId,
            max_distance_meters: maxDistance,
        };
        /* eslint-enable camelcase */

        SearchTracking.updateAction(SEARCH_TRACKING_ACTIONS.LIST_SEARCH_NEXT);
        trackSearchClickedLoadMoreSpots({parkingType});

        const formatOptions = {
            powerBookingSource,
        };

        return dispatch({
            type: SPOTS_GET_BULK_TRANSIENT_V2,
            payload: getBulkSearchTransientFacilitiesLoadMoreSpots(
                {
                    // Below includes walking distance until BE includes it in @next
                    loadMoreSpotsUrl: `${loadMoreSpotsUrl}&include_walking_distance=true`,
                },
                requestData,
                formatOptions
            )
                .then(response => ({
                    loadMoreSpotsUrl: response.loadMoreSpotsUrl,
                    results: [...prevState.spots.data, ...response.results], // append to existing spots
                }))
                .catch(error => handleError(dispatch, error)),
        });
    };
};
