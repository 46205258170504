import { SEARCH_REQUEST_SET_TERM, SEARCH_REQUEST_UPDATE_TIMES, SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES, } from '../search-request/search-request-actions';
import { FILTERS_RESET, FILTERS_UPDATE, FILTER_SET_SHOW_TOTAL, } from './filters-actions';
import { initialReservationTypes } from './filters-utils';
import { initialRatingTypes } from './rating-filter-utils';
import { SPOTS_GET_BULK_TRANSIENT_V2, SPOTS_GET_TRANSIENT_V2, } from 'store/spots/spots-actions-v2';
import { ActionType } from 'redux-promise-middleware';
import { v4 as uuidV4 } from 'uuid';
export const initialState = {
    // filterId is used to determine if the filters have changed
    filterId: uuidV4(),
    numActive: 0,
    ratingTypes: { ...initialRatingTypes },
    activeRatingTypes: [],
    amenities: {},
    activeAmenities: [],
    reservationTypes: { ...initialReservationTypes },
    activeReservationType: 'all',
    viaQuickFilter: false,
    walkingDistanceLimitInMinutes: 0,
    // This value is used to identify if we are currently showing the total or the advertised value
    showTotal: false,
};
export default function filters(state = initialState, { type, payload }) {
    switch (type) {
        case FILTERS_RESET:
        case SEARCH_REQUEST_SET_TERM: {
            return {
                ...initialState,
                filterId: uuidV4(),
            };
        }
        case FILTERS_UPDATE: {
            const newState = {
                ...state,
                ...payload,
                filterId: uuidV4(),
            };
            newState.numActive = newState.activeAmenities.length;
            return newState;
        }
        // Reset walking distance limit when a new search is triggered
        case `${SPOTS_GET_TRANSIENT_V2}_${ActionType.Pending}`:
        case `${SPOTS_GET_BULK_TRANSIENT_V2}_${ActionType.Pending}`:
        case SEARCH_REQUEST_UPDATE_TERMS_AND_POWER_BOOKING_TIMES:
        case SEARCH_REQUEST_UPDATE_TIMES: {
            return {
                ...state,
                filterId: uuidV4(),
                walkingDistanceLimitInMinutes: 0,
            };
        }
        case FILTER_SET_SHOW_TOTAL: {
            return {
                ...state,
                showTotal: !state.showTotal,
            };
        }
        default:
            return state;
    }
}
