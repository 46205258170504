import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GooglePlacesSearchInput, PoweredByGoogle } from '@spothero/ui-backlog';
import { Text } from '@spothero/ui';
import { useGoogleMapsLibraries } from 'common/google-map-context/GoogleMapContext';
const SearchInputPropTypes = {
    isMonthly: PropTypes.bool,
    isSearching: PropTypes.bool,
    placeholder: PropTypes.string,
    searchString: PropTypes.string,
    searchBounds: PropTypes.shape({
        swLat: PropTypes.number,
        swLng: PropTypes.number,
        neLat: PropTypes.number,
        neLng: PropTypes.number,
    }),
    showLabel: PropTypes.bool,
    onSuggestSelect: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    biasOptions: PropTypes.shape({
        location: PropTypes.shape({
            latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            longitude: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
        }),
        radius: PropTypes.number,
    }),
};
const SearchInput = ({ isMonthly, placeholder = 'Address, Place, City or Venue', searchString = '', isSearching, showLabel = true, biasOptions, searchBounds, onChange, onSuggestSelect, onSubmit, ...props }) => {
    const { googleMapsLoaded, googleMapsLibraries } = useGoogleMapsLibraries();
    // Don't render the search input on SSR or until the Google Maps API has loaded on the client
    if (typeof window === 'undefined' || !googleMapsLoaded) {
        return null;
    }
    const handleClearClick = () => {
        onChange('');
    };
    const handleChange = evt => {
        onChange(evt.target.value);
    };
    const handleSuggestSelect = ({ label: labelSearchString, location, id, place_id: placeId, reference, }) => {
        /* eslint-disable camelcase */
        const submitParams = {
            search_string: labelSearchString,
            latitude: location.lat,
            longitude: location.lng,
            google_places_id: id,
            google_places_place_id: placeId,
            google_places_reference: reference,
        };
        /* eslint-enable camelcase */
        onSubmit(submitParams, true);
        if (onSuggestSelect) {
            onSuggestSelect(labelSearchString);
        }
    };
    const classes = classNames('SearchInput', {
        'SearchInput-searching': isSearching,
    });
    const bounds = searchBounds
        ? new googleMapsLibraries.core.LatLngBounds({
            lat: searchBounds.swLat,
            lng: searchBounds.swLng,
        }, {
            lat: searchBounds.neLat,
            lng: searchBounds.neLng,
        })
        : null;
    return (<GooglePlacesSearchInput {...props} className={classes} placeholder={isMonthly ? 'City, Address or Place' : placeholder} country={['us', 'ca']} bounds={bounds} defaultValue={searchString} loading={isSearching} disabled={isSearching} label={showLabel ? (<Text variant="body2" marginBottom={2} fontWeight="semibold" color="gray.dark">
                        Book Parking Near
                    </Text>) : null} poweredByGoogleFooter={<PoweredByGoogle />} 
    // TODO: Remove dependency on global window.google.maps
    googleMaps={window.google.maps} onClear={handleClearClick} onChange={handleChange} onSuggestionSelect={handleSuggestSelect} biasOptions={biasOptions} aria-label="Search for parking"/>);
};
SearchInput.defaultProps = {
    placeholder: 'Address, Place, City or Venue',
    showLabel: true,
    searchString: '',
};
export default SearchInput;
