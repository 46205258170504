import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import Config from '@/config/index';
import FacilityAPI from 'api/facility';
import CreditAPI from 'api/credit';
import ReservationAPI from 'api/reservation';
import FormatUtils from 'utils/format';
import StripeUtils from 'utils/stripe';
import ErrorUtils from 'utils/error-utils';
import {userUpdate} from '../user/user-actions';
import {push} from '../router/router-actions';
import {FAILURE_LOCATIONS} from 'segment/events/checkout-failed';
import {DEFAULT_ERROR_SUBTITLE} from './checkout-constants';
import {getExtensionUpsellRates} from 'api/search-v2/extension-upsells/getExtensionUpsellRates';
import {purchase as checkoutPowerBooking} from '../../api/checkout';
import {getRentalAddons} from 'api/rental-addons';
export const CHECKOUT_FETCH_RATES = 'CHECKOUT_FETCH_RATES';
export const CHECKOUT_FETCH_UPSELL_RATES = 'CHECKOUT_FETCH_UPSELL_RATES';
export const CHECKOUT_SHOW_CHANGE_DATE_TIME = 'CHECKOUT_SHOW_CHANGE_DATE_TIME';
export const CHECKOUT_HIDE_CHANGE_DATE_TIME = 'CHECKOUT_HIDE_CHANGE_DATE_TIME';
export const CHECKOUT_HIDE_RECOMMENDED_SPOTS_DETAILS =
    'CHECKOUT_HIDE_RECOMMENDED_SPOTS_DETAILS';
export const CHECKOUT_UPDATE_DATA = 'CHECKOUT_UPDATE_DATA';
export const CHECKOUT_USER_CHANGE = 'CHECKOUT_USER_CHANGE';

export const CHECKOUT_SHOW_RATE_DETAILS = 'CHECKOUT_SHOW_RATE_DETAILS';
export const CHECKOUT_HIDE_RATE_DETAILS = 'CHECKOUT_HIDE_RATE_DETAILS';
export const CHECKOUT_TOGGLE_RATE_ONLINE_COMMUTER_DETAILS =
    'CHECKOUT_TOGGLE_RATE_ONLINE_COMMUTER_DETAILS';
export const CHECKOUT_TOGGLE_TIME_EXTENDED_DETAILS =
    'CHECKOUT_TOGGLE_TIME_EXTENDED_DETAILS';
export const CHECKOUT_HIDE_REDUNDANT_MONTHLY_DETAILS =
    'CHECKOUT_HIDE_REDUNDANT_MONTHLY_DETAILS';

export const CHECKOUT_PAYMENT_TOKEN_REQUESTED =
    'CHECKOUT_PAYMENT_TOKEN_REQUESTED';
export const CHECKOUT_PAYMENT_ERROR = 'CHECKOUT_PAYMENT_ERROR';
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
export const CHECKOUT_ON_PURCHASE = 'CHECKOUT_ON_PURCHASE';
export const CHECKOUT_PURCHASE_ERROR = 'CHECKOUT_PURCHASE_ERROR';
export const CHECKOUT_CLEAR_CUSTOMER = 'CHECKOUT_CLEAR_CUSTOMER';
export const CHECKOUT_INIT_USER_DATA = 'CHECKOUT_INIT_USER_DATA';
export const CHECKOUT_FETCH_RENTAL_ADDONS = 'CHECKOUT_FETCH_RENTAL_ADDONS';
export const CHECKOUT_SHOW_NO_INVENTORY = 'CHECKOUT_SHOW_NO_INVENTORY';
export const CHECKOUT_SET_EXTENSION_UPSELL_SELECTED =
    'CHECKOUT_SET_EXTENSION_UPSELL_SELECTED';
export const CHECKOUT_TOGGLE_ADDON = 'CHECKOUT_TOGGLE_ADDON';
export const CHECKOUT_SWAP_UPSELL_RATE = 'CHECKOUT_SWAP_UPSELL_RATE';
export const CHECKOUT_EXTENSION_UPSELL_INFO_LOADED =
    'CHECKOUT_EXTENSION_UPSELL_INFO_LOADED';
export const CHECKOUT_SET_VEHICLE_SIZE_EXCEEDED =
    'CHECKOUT_SET_VEHICLE_SIZE_EXCEEDED';

export const showNoInventory = errorMessage => {
    return {
        type: CHECKOUT_SHOW_NO_INVENTORY,
        payload: errorMessage,
    };
};

export const purchaseError = ({
    dispatch,
    errors,
    extra,
    failureCallback = () => {}, // eslint-disable-line no-empty-function
    failureLocation = FAILURE_LOCATIONS.BACKEND_RESERVE,
}) => {
    let newState;
    const messages = [];
    const codes = [];
    const subtitles = [];

    if (errors[0].code === 'previously_purchased_monthly') {
        const {created, parkingPassURL} = JSON.parse(errors[0].messages[0]);

        messages.push(errors[0].code);
        codes.push(errors[0].code);

        newState = {
            redundantMonthlyReservationModalDate: created,
            redundantMonthlyReservationModalParkingPassURL: parkingPassURL,
            showRedundantMonthlyReservationModal: true,
        };
    } else {
        errors.forEach(error => {
            let errorMessage = '';

            if (isObject(error.messages[0])) {
                if (!isUndefined(error.messages[0].all_errors)) {
                    errorMessage = error.messages[0].all_errors[0];
                    messages.push(errorMessage);
                }
            } else {
                errorMessage = error.messages[0];
                messages.push(errorMessage);
            }

            if (
                error.code === 'no_inventory' ||
                error.code === 'no_monthly_inventory' ||
                error.code === 'event_package_rates_not_available'
            ) {
                dispatch(showNoInventory(errorMessage));
            }

            codes.push(error.code);

            if (error.title) {
                subtitles.push(error.title);
            }
        });

        if (
            errors[0].code === 'invalid_referral' &&
            extra &&
            !isUndefined(extra.spothero_credit)
        ) {
            dispatch(
                userUpdate({
                    credit: extra.spothero_credit,
                })
            );
        }

        newState = {
            checkoutError: {
                subtitle: subtitles.length
                    ? subtitles[0]
                    : DEFAULT_ERROR_SUBTITLE,
                errors: messages,
                codes,
            },
        };
    }

    failureCallback(messages, failureLocation, codes);

    return dispatch({
        type: CHECKOUT_PURCHASE_ERROR,
        payload: newState,
    });
};

export const setExtensionUpsellSelected = ({selected}) => {
    return dispatch => {
        dispatch({
            type: CHECKOUT_SET_EXTENSION_UPSELL_SELECTED,
            payload: {
                selected,
            },
        });
    };
};

export const swapUpsellRate = ({rate}) => ({
    type: CHECKOUT_SWAP_UPSELL_RATE,
    payload: {
        rate,
    },
});

export const toggleAddon = ({addon, addonType}) => ({
    type: CHECKOUT_TOGGLE_ADDON,
    payload: {addon, addonType},
});

export const selectUpsellRate = ({rate, selected}) => {
    return dispatch => {
        dispatch(setExtensionUpsellSelected({selected}));
        dispatch(swapUpsellRate({rate}));
    };
};

export const fetchExtensionUpsellRates = ({spotId, starts, ends}) => {
    return (dispatch, getState) => {
        const {
            checkout: {extensionUpsellSelected, spot, vehicleInfoId},
        } = getState();

        return dispatch({
            type: CHECKOUT_FETCH_UPSELL_RATES,
            payload: getExtensionUpsellRates({
                facilityId: spotId,
                facilitySlug: spot.facility.slug,
                timezone: spot.timezone,
                starts,
                ends,
                vehicleInfoId,
                rates: spot.hourly_rates,
                extensionHours: [1, 2, 3],
            }),
        }).then(({value}) => {
            // If rates call and already selected upsell, check if can upsell and if so, swap rates, else deselect
            if (extensionUpsellSelected !== null) {
                if (
                    value.extensionUpsellRateInfo[
                        spot.selectedRate?.rule_group_id
                    ]?.upsellItems?.length
                ) {
                    dispatch(
                        swapUpsellRate({
                            rate:
                                value.extensionUpsellRateInfo[
                                    spot.selectedRate?.rule_group_id
                                ].upsellItems[extensionUpsellSelected].rate,
                        })
                    );
                } else {
                    dispatch(setExtensionUpsellSelected({selected: null}));
                }
            }
        });
    };
};

export const fetchRentalAddons = ({
    facilityId,
    ruleGroupId,
    starts,
    ends,
    email,
}) => {
    return dispatch => {
        return dispatch({
            type: CHECKOUT_FETCH_RENTAL_ADDONS,
            payload: getRentalAddons({
                facilityId,
                ruleGroupId,
                starts,
                ends,
                email,
            }),
        });
    };
};

export const fetchRates = ({
    spotId,
    starts,
    ends,
    airport,
    eventPackageId,
    includeFacility,
    meta,
}) => {
    return (dispatch, getState) => {
        const {
            searchRequest: {
                eid: eventId,
                monthly,
                powerBooking,
                powerBookingPeriods,
                powerBookingSource,
            },
            checkout: {vehicleInfoId},
        } = getState();

        return dispatch({
            type: CHECKOUT_FETCH_RATES,
            payload: FacilityAPI.getV2Rates({
                spotId,
                starts,
                ends,
                eventId,
                monthly,
                airport,
                eventPackageId,
                includeFacility,
                vehicleInfoId,
                powerBooking,
                powerBookingPeriods,
                powerBookingSource,
            }),
            meta: {
                ...meta,
                spotId,
                starts,
                ends,
                eventId,
                monthly,
                airport,
                timezone: getState().city.data.timezone,
                powerBooking,
                powerBookingPeriods,
                powerBookingSource,
            },
        });
    };
};

export const showChangeDateTime = ({
    title = 'Change Time',
    message = 'Just FYI, the price or availability might change if you choose different options here.',
    buttonText = null,
    isStaleStart = false,
} = {}) => {
    return {
        type: CHECKOUT_SHOW_CHANGE_DATE_TIME,
        payload: {
            title,
            message,
            buttonText,
            isStaleStart,
        },
    };
};

export const showChangeDateTimeForScan2payLanding = ({
    title = 'How long do you need to park?',
    message = '',
    buttonText = 'Continue to Checkout',
    isStaleStart = false,
} = {}) => {
    return {
        type: CHECKOUT_SHOW_CHANGE_DATE_TIME,
        payload: {
            title,
            message,
            buttonText,
            isStaleStart,
        },
    };
};

export const hideChangeDateTime = (showRecommendedSpots = false) => {
    return {
        type: CHECKOUT_HIDE_CHANGE_DATE_TIME,
        payload: {
            showRecommendedSpots,
        },
    };
};

export const hideRecommendedSpots = (showChangeDateTimeModal = false) => {
    return {
        type: CHECKOUT_HIDE_RECOMMENDED_SPOTS_DETAILS,
        payload: {
            showChangeDateTimeModal,
        },
    };
};

export const updateData = (data, meta) => {
    return {
        type: CHECKOUT_UPDATE_DATA,
        payload: data,
        meta,
    };
};

export const userChange = ({
    user,
    userNormalized,
    isMonthly,
    extraReservationItems,
    overrideVehicle = null,
}) => {
    return {
        type: CHECKOUT_USER_CHANGE,
        payload: {
            user,
            userNormalized,
            isMonthly,
            extraReservationItems,
            overrideVehicle,
        },
    };
};

export const showRateDetails = (section = null) => {
    return {
        type: CHECKOUT_SHOW_RATE_DETAILS,
        payload: {
            section: isString(section) ? section : null,
        },
    };
};

export const hideRateDetails = () => {
    return {
        type: CHECKOUT_HIDE_RATE_DETAILS,
    };
};

export const toggleOnlineCommuterDetails = () => {
    return {
        type: CHECKOUT_TOGGLE_RATE_ONLINE_COMMUTER_DETAILS,
    };
};

export const toggleTimeExtendedDetails = () => {
    return {
        type: CHECKOUT_TOGGLE_TIME_EXTENDED_DETAILS,
    };
};

export const hideRedundantMonthlyDetails = () => {
    return {
        type: CHECKOUT_HIDE_REDUNDANT_MONTHLY_DETAILS,
    };
};

export const submitPromo = ({
    email,
    price,
    promoCode,
    ruleGroupId,
    ruleType,
    spotId,
    currencyType: facilityCurrencyType,
}) => {
    return (dispatch, getState) => {
        const {
            checkout: {purchaseOnBehalfOfCustomer},
            searchRequest: {starts, ends},
        } = getState();
        const meta = {
            email,
            price,
            promoCode,
            ruleGroupId,
            ruleType,
            spotId,
            currencyType: facilityCurrencyType,
            starts,
            ends,
        };

        const data = CreditAPI.checkPromoCode(meta)
            .then(
                ({
                    codeType,
                    discountAmount,
                    percentageOff,
                    percentageOffDiscountMax,
                    ...response
                }) => {
                    const currencySymbol = FormatUtils.currencySymbol(
                        facilityCurrencyType
                    );
                    const discountCoversCharge = price - discountAmount <= 0;
                    const discountDisplayAmount = `${currencySymbol}${discountAmount}`;

                    return {
                        paymentRequired: !(
                            discountCoversCharge && purchaseOnBehalfOfCustomer
                        ),
                        paymentRequiredNoCharge:
                            discountCoversCharge && !purchaseOnBehalfOfCustomer,
                        promoCode: {
                            ...response,
                            codeType,
                            discountAmount,
                            percentageOff,
                            code: promoCode.toUpperCase(),
                            discountDisplayAmount,
                            promoCodeDetails: percentageOff
                                ? `${percentageOff}% off${
                                      percentageOffDiscountMax
                                          ? ` up to ${currencySymbol}${percentageOffDiscountMax}`
                                          : ''
                                  }`
                                : discountDisplayAmount,
                        },
                        incomingPromoCode: null,
                    };
                }
            )
            .catch(err => {
                let message = null;

                try {
                    // If error is here, means it was just a bad promo code. No need to alert Sentry
                    message = err.data.data.errors.shift().messages[0];
                } catch {
                    message = null;
                }

                // If error wasn't there, something else happens, so let's send an error to Sentry
                if (message) {
                    return Promise.reject(new Error(message));
                } else {
                    ErrorUtils.sendSentryException(err);

                    return Promise.reject(
                        new Error('Sorry, this promo code is not enabled.')
                    );
                }
            });

        return dispatch(updateData(data, meta));
    };
};

export const purchase = ({
    reservationData,
    isAdmin,
    isAirportCheckoutV2FeatureFlagEnabled,
    onSuccess,
    onFailure = () => {}, // eslint-disable-line no-empty-function
}) => {
    return (dispatch, getState) => {
        const {
            selectedCreditCard,
            newCardInfo,
            purchaseForCustomerDescription,
            initialReservationId,
        } = reservationData;
        const {
            user: {data: userData},
            searchRequest: {
                monthly: isMonthly,
                rebook_reservation_id: rebookReservationId,
                starts: monthlyStarts,
                eid: eventId,
                airport: isAirport,
            },
            spot: {
                selected: {facility, selectedRate},
            },
            checkout,
        } = getState();
        const checkoutData = {
            user: userData,
            isMonthly,
            isAirport,
            monthlyStarts,
            eventId,
            purchaseForCustomerDescription,
            initialReservationId,
            ...checkout,
            ...reservationData,
        };

        if (checkout.isRebookingReservation && rebookReservationId) {
            return dispatch({
                type: CHECKOUT_ON_PURCHASE,
                payload: ReservationAPI.rebook({
                    checkoutData,
                    selectedRate,
                    facility,
                    rebookReservationId,
                    onError: (errors, extra) => {
                        purchaseError({
                            dispatch,
                            errors,
                            extra,
                            failureCallback: onFailure,
                            failureLocation: FAILURE_LOCATIONS.BACKEND_REBOOK,
                        });
                    },
                    routerPush: (...args) => dispatch(push(...args)),
                }),
            });
        } else if (selectedCreditCard === 'new') {
            dispatch({
                type: CHECKOUT_PAYMENT_TOKEN_REQUESTED,
            });

            StripeUtils.createToken({
                name: newCardInfo.fullName,
                isAdmin,
                onSuccess(tokenId) {
                    return dispatch({
                        type: CHECKOUT_ON_PURCHASE,
                        payload: ReservationAPI.add({
                            isAirportCheckoutV2FeatureFlagEnabled,
                            checkoutData,
                            selectedRate,
                            monthlyStarts,
                            facility,
                            checkoutStartTime: checkout.checkoutStartTime,
                            stripeToken: tokenId,
                            source: Config.isMobile ? 'mobile-web' : 'web',
                            setNewCardAsDefault: newCardInfo.isDefault,
                            onSuccess,
                            onError: (errors, extra) => {
                                purchaseError({
                                    dispatch,
                                    errors,
                                    extra,
                                    failureCallback: onFailure,
                                    failureLocation:
                                        FAILURE_LOCATIONS.BACKEND_RESERVE,
                                });
                            },
                            routerPush: (...args) => dispatch(push(...args)),
                        }),
                    });
                },
                onError(errorMessage, errorCode = '') {
                    onFailure(
                        [errorMessage],
                        FAILURE_LOCATIONS.FRONTEND_PAYMENT[errorCode],
                        [errorCode]
                    );

                    return dispatch({
                        type: CHECKOUT_PAYMENT_ERROR,
                        payload: {
                            errors: [errorMessage],
                        },
                    });
                },
            });
        } else {
            const isPurchasingWithPaymentRequest =
                checkoutData.stripePaymentType &&
                checkoutData.stripePaymentType !== 'cc';

            return dispatch({
                type: CHECKOUT_ON_PURCHASE,
                payload: ReservationAPI.add({
                    isAirportCheckoutV2FeatureFlagEnabled,
                    checkoutData,
                    selectedRate,
                    monthlyStarts,
                    facility,
                    checkoutStartTime: checkout.checkoutStartTime,
                    source: Config.isMobile ? 'mobile-web' : 'web',
                    ...(isPurchasingWithPaymentRequest && {
                        stripePaymentType: checkoutData.stripePaymentType,
                    }),
                    onSuccess,
                    onError: (errors, extra) => {
                        purchaseError({
                            dispatch,
                            errors,
                            extra,
                            failureCallback: onFailure,
                            failureLocation: FAILURE_LOCATIONS.BACKEND_RESERVE,
                        });
                    },
                    routerPush: (...args) => dispatch(push(...args)),
                }),
                meta: {
                    isPurchasingWithPaymentRequest,
                },
            });
        }
    };
};

export const purchaseBulkReservation = ({
    reservationData,
    isAdmin,
    onSuccess,
    onFailure = () => {}, // eslint-disable-line no-empty-function
}) => {
    return (dispatch, getState) => {
        const {
            selectedCreditCard,
            newCardInfo,
            purchaseForCustomerDescription,
            initialReservationId,
        } = reservationData;
        const {
            user: {data: userData},
            searchRequest: {powerBookingPeriods, powerBooking: isPowerBooking},
            spot: {
                selected: {facility, bulkPowerBookingRates},
            },
            checkout,
            city: {data: city},
        } = getState();
        const checkoutData = {
            user: userData,
            powerBookingPeriods,
            purchaseForCustomerDescription,
            initialReservationId,
            ...checkout,
            ...reservationData,
        };

        const apiToDispatch = checkoutPowerBooking;

        if (selectedCreditCard === 'new') {
            dispatch({
                type: CHECKOUT_PAYMENT_TOKEN_REQUESTED,
            });

            StripeUtils.createToken({
                name: newCardInfo.fullName,
                isAdmin,
                onSuccess(tokenId) {
                    return dispatch({
                        type: CHECKOUT_ON_PURCHASE,
                        payload: apiToDispatch({
                            checkoutData,
                            isPowerBooking,
                            bulkPowerBookingRates,
                            facility,
                            checkoutStartTime: checkout.checkoutStartTime,
                            stripeToken: tokenId,
                            source: Config.isMobile ? 'mobile-web' : 'web',
                            setNewCardAsDefault: newCardInfo.isDefault,
                            onSuccess,
                            city,
                            onError: (errors, extra) =>
                                purchaseError({
                                    dispatch,
                                    errors,
                                    extra,
                                    failureCallback: onFailure,
                                    failureLocation:
                                        FAILURE_LOCATIONS.BACKEND_RESERVE,
                                }),
                            routerPush: (...args) => dispatch(push(...args)),
                        }),
                    });
                },
                onError(errorMessage, errorCode = '') {
                    onFailure(
                        [errorMessage],
                        FAILURE_LOCATIONS.FRONTEND_PAYMENT[errorCode],
                        [errorCode]
                    );

                    return dispatch({
                        type: CHECKOUT_PAYMENT_ERROR,
                        payload: {
                            errors: [errorMessage],
                        },
                    });
                },
            });
        } else {
            const isPurchasingWithPaymentRequest =
                checkoutData.stripePaymentType &&
                checkoutData.stripePaymentType !== 'cc';

            return dispatch({
                type: CHECKOUT_ON_PURCHASE,
                payload: apiToDispatch({
                    checkoutData,
                    isPowerBooking,
                    bulkPowerBookingRates,
                    facility,
                    checkoutStartTime: checkout.checkoutStartTime,
                    source: Config.isMobile ? 'mobile-web' : 'web',
                    ...(isPurchasingWithPaymentRequest && {
                        stripePaymentType: checkoutData.stripePaymentType,
                    }),
                    onSuccess,
                    city,
                    onError: (errors, extra) =>
                        purchaseError({
                            dispatch,
                            errors,
                            extra,
                            failureCallback: onFailure,
                            failureLocation: FAILURE_LOCATIONS.BACKEND_RESERVE,
                        }),
                    routerPush: (...args) => dispatch(push(...args)),
                }),
                meta: {
                    isPurchasingWithPaymentRequest,
                },
            });
        }
    };
};

export const clearCustomer = () => {
    return (dispatch, getState) => {
        const {
            checkout: {adminUser},
            user: {data: user},
        } = getState();

        dispatch({
            type: CHECKOUT_CLEAR_CUSTOMER,
            payload: !isEmpty(adminUser) ? adminUser : user,
        });
    };
};

export const checkoutInitUserData = data => {
    return {
        type: CHECKOUT_INIT_USER_DATA,
        payload: data,
    };
};

export const sendCheckoutError = errorMessage => {
    return {
        type: CHECKOUT_ERROR,
        payload: {
            errors: [errorMessage],
        },
    };
};

export const setVehicleSizeExceeded = isVehicleSizeExceeded => {
    return {
        type: CHECKOUT_SET_VEHICLE_SIZE_EXCEEDED,
        payload: {
            vehicleSizeExceeded: isVehicleSizeExceeded,
        },
    };
};
