import { ActionType } from 'redux-promise-middleware';
import AuthenticationAPI from 'api/authentication';
import UserAPI from 'api/user';
import UserUtils, { creditCardTransformer, paypalCardTransformer, PAYPAL_WALLET_VALUE, } from 'utils/user-utils';
import CreditCardAPI from 'api/credit-card';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_UPDATE = 'USER_UPDATE';
export const ADD_VEHICLE = 'ADD_VEHICLE';
export const userLogin = (data) => {
    const requestData = {
        ...data,
        includes: UserUtils.INCLUDES,
    };
    return {
        type: USER_LOGIN,
        payload: AuthenticationAPI.login(requestData),
    };
};
export const userLogout = (status) => {
    if (status === UserUtils.AUTH_STATE.GUEST ||
        status === UserUtils.AUTH_STATE.LOGGED_OUT) {
        // if logging out as a guest, don't hit the logout endpoint, just fulfill the action
        return {
            type: `${USER_LOGOUT}_${ActionType.Fulfilled}`,
        };
    }
    return {
        type: USER_LOGOUT,
        payload: AuthenticationAPI.logout(),
    };
};
export const userUpdate = (data) => {
    return {
        type: USER_UPDATE,
        payload: data,
    };
};
export const removeCreditCard = (card) => async (dispatch, getState) => {
    const { user: { data: userData }, } = getState();
    try {
        await CreditCardAPI.remove({
            // STRICT - @ts-expect-error - from migration
            userId: userData.id,
            cardExternalId: card.externalId,
        });
        const cardOverrideData = 
        // @ts-expect-error - needs credit card winnowing
        card.wallet === PAYPAL_WALLET_VALUE
            ? { paypalCard: null }
            : {
                creditCards: userData.creditCards.filter(
                // @ts-expect-error - needs credit card winnowing
                userCard => userCard.externalId !== card.externalId),
            };
        dispatch(userUpdate({
            ...userData,
            ...cardOverrideData,
        }));
        return { errors: false };
    }
    catch {
        return { errors: true };
    }
};
export const addCardToUser = (card) => (dispatch, getState) => {
    const { user: { data: userData }, } = getState();
    if (!card) {
        return;
    }
    const newCard = 
    // @ts-expect-error - from migration
    card.wallet === PAYPAL_WALLET_VALUE
        ? // @ts-expect-error - from migration
            paypalCardTransformer(card)
        : // @ts-expect-error - from migration
            creditCardTransformer(card);
    if (newCard.wallet !== null && newCard.wallet !== PAYPAL_WALLET_VALUE) {
        return;
    }
    if (userData.status !== UserUtils.AUTH_STATE.USER) {
        return;
    }
    if (newCard.wallet === null &&
        userData.creditCards.find(
        // @ts-expect-error - from migration
        userCard => userCard.externalId === newCard.externalId)) {
        return;
    }
    const paymentOverrides = {};
    if (newCard.wallet === PAYPAL_WALLET_VALUE) {
        // @ts-expect-error - from migration
        paymentOverrides.paypalCard = newCard;
    }
    else {
        // @ts-expect-error - from migration
        paymentOverrides.creditCards = userData.creditCards
            .map(userCard => {
            // @ts-expect-error - from migration
            if (newCard.isDefault && userCard.isDefault) {
                return { ...userCard, isDefault: false };
            }
            return userCard;
        })
            // @ts-expect-error - from migration
            .concat([newCard]);
    }
    dispatch(userUpdate({ ...userData, ...paymentOverrides }));
};
export const addVehicle = (vehicleToAdd) => {
    return {
        type: ADD_VEHICLE,
        payload: vehicleToAdd,
    };
};
export const refreshUser = () => async (dispatch) => {
    const updatedUser = await UserAPI.getUser();
    dispatch(userUpdate({ ...updatedUser, status: UserUtils.AUTH_STATE.USER }));
};
