import APIUtils from '@spothero/utils/api';
import ObjectUtils from '@spothero/utils/object';
import ErrorUtils from 'utils/error-utils';
/**
 * Get destination data for event packages
 *
 * @function getVenue
 * @param {string} id - Destination ID
 * @returns {Promise<object>} - API response
 * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Destinations/get_destinations_id_event_packages|Documentation}
 */
export const getVenue = (id) => {
    return APIUtils.get(`/destinations/${id}/event-packages/`)
        .then(response => {
        try {
            return ObjectUtils.camelizeKeys(response.data.data);
        }
        catch (error) {
            return Promise.reject(new Error('Response shape unexpected.'));
        }
    })
        .catch(err => ErrorUtils.transformAndReject(err));
};
