import { combineReducers } from 'redux';
import checkout from './checkout/checkout-reducer';
import city from './city/city-reducer';
import destination from './destination/destination-reducer';
import event from './event/event-reducer';
import eventPackage from './event-package/event-package-reducer';
import filters from './filters/filters-reducer';
import notification from './notification/notification-reducer';
import promotion from './promotion/promotion-reducer';
import search from './search/search-reducer';
import searchRequest from './search-request/search-request-reducer';
import smsPermissions from './sms-permissions/sms-permissions-reducer';
import spot from './spot/spot-reducer';
import spots from './spots/spots-reducer';
import user from './user/user-reducer';
import category from './category/category-reducer';
import page from './page/page-reducer';
import cms from './cms/cms-reducer';
import appRating from './app-rating/app-rating-reducer';
import cloudfront from './cloudfront/reducer';
import payments from './payments/payments-reducer';
const rootReducer = combineReducers({
    category,
    checkout,
    city,
    destination,
    event,
    eventPackage,
    filters,
    notification,
    promotion,
    search,
    searchRequest,
    smsPermissions,
    spot,
    spots,
    user,
    page,
    cms,
    appRating,
    cloudfront,
    payments,
});
export default rootReducer;
