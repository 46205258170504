/**
 * @module api/reservation
 */
import get from 'lodash/get';
import includes from 'lodash/includes';
import dayjs from 'utils/dayjs-timezone';
import APIUtils from '@spothero/utils/api';
import FormatUtils from 'utils/format';
import StorageUtils from '@spothero/utils/storage';
import UrlUtils from '@spothero/utils/url';
import Config from '@/config/index';
import UserUtils from 'utils/user-utils';
import SearchTracking from 'utils/search-tracking';
import SegmentUtils from 'utils/segment';
import CheckoutUtils from 'utils/checkout-utils';
import ErrorUtils from 'utils/error-utils';
import {FACILITY_TYPES} from 'utils/types/facility';
import {purchase} from './checkout';

export const RESERVATION_INCLUDES =
    'phone_number,facility.phone_number_required,vehicle,event,access_hours,rate_title,access_hours_comment,monthly_agreement_link,amenities_full,rule_reservation_type,partner,after_first_month';
export const UNLISTED_VEHICLE_VALUE = 0;

/**
 *
 * @param {object} errorResponse Pulls error out of the response
 * @returns {object} returns errors and extra
 * @example
 *  {
 *    errors: Array<{messages: Array<string>}>,
 *    extra: boolean,
 *  }
 */
export const getErrorsFromResponse = errorResponse => {
    // error object is different for <500 and >=500 status codes
    const data =
        get(errorResponse, 'data.data') ||
        get(errorResponse, 'response.data.data');
    let errors = [];
    let extra = false;

    if (data) {
        errors = data.errors;
        extra = data.extra;
    }

    if (!errors.length || !errors[0].messages.length) {
        errors = [
            {
                messages: [
                    'An error occurred while reserving your parking spot. Please refresh the page and try again.',
                ],
            },
        ];
    }

    return {
        errors,
        extra,
    };
};

export const redirectToConfirmationPage = ({
    orderId,
    accessKey,
    routerPush,
    failedPeriods = [],
}) => {
    if (!Config.isDev) {
        window.location.href = `/receipt?${UrlUtils.createQueryString({
            id: orderId,
            key: accessKey,
            ...(failedPeriods?.length && {failedPeriods}),
        })}`;
    } else {
        routerPush(
            `/confirmation?${UrlUtils.createQueryString({
                id: orderId,
                key: accessKey,
            })}`
        );
    }
};

/**
 * Object containing all Reservation API methods
 *
 * @property {add} add Add reservation
 * @property {rebook} rebook Customer Hero booking a replacement spot for a user with the money already spent
 * @property {updatePhone} updatePhone update phone on reservation
 */
const ReservationAPI = {
    /**
     * Add reservation to spothero
     *
     * @function add
     * @param {object} param Top level param
     * @param {CheckoutData} param.checkoutData Data from checkout form
     * @param {SelectedRate} param.selectedRate Selected Rate for spot
     * @param {object} param.monthlyStarts - Date time string
     * @param {object} param.facility - Facility object
     * @param {object} param.stripeToken - object from stripe
     * @param {string} param.stripePaymentType - stripe payment type
     * @param {boolean} param.setNewCardAsDefault - is this new card default
     * @param {boolean} param.includeVehicleInResponse - is vehicle to be to be in response
     * @param {boolean} param.includeFacilityInResponse - is facility to be to be in response
     * @param {object} param.source - Rental source
     * @param {object} param.rentalSourceReferrer - Rental source referrer
     * @param {Function} param.onSuccess - callback on success
     * @param {Function} param.onError - callback on error
     * @param {Function} param.routerPush - callback for router push
     * @param {boolean} param.isAirportCheckoutV2FeatureFlagEnabled - Is airport checkout v2 feature flag enabled
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Reservations/post_reservations_reserve|Documentation}
     * @returns {void}
     * @todo Facility object deficition
     */
    async add({
        checkoutData,
        selectedRate,
        monthlyStarts = null,
        facility,
        stripeToken = false,
        stripePaymentType = 'cc',
        setNewCardAsDefault = false,
        includeVehicleInResponse = false,
        includeFacilityInResponse = false,
        source = null,
        rentalSourceReferrer = null,
        onSuccess,
        onError,
        routerPush,
        isAirportCheckoutV2FeatureFlagEnabled,
    }) {
        const {
            currencyType,
            user,
            promoCode,
            isMonthly,
            isAirport,
            eventId,
            paymentRequired,
            purchaseForCustomer,
            purchaseForCustomerDescription,
            purchaseOnBehalfOfCustomer,
            initialReservationId,
            affiliate,
            selectedCreditCard,
            licensePlateRequired,
            selectedLicensePlate,
            licensePlateState,
            newLicensePlate,
            newLicensePlateName,
            newLicensePlateIsDefault,
            referralSource,
            smsParkingPass,
            forceRedundantMonthly,
            vehicleInfoId,
            vehicleProfileId,
            licensePlateUnknown,
            vehicleMake,
            vehicleModel,
            vehicleColor,
            unlistedModel,
            saveVehicleAsDefault,
            contractFullName,
            paymentOverlay,
            spot,
            paypal,
            vehicleSelectPresent,
        } = checkoutData;
        const eventPackageId = spot?.event_package?.id;
        const facilityId = spot?.spotId;

        if (isAirport && isAirportCheckoutV2FeatureFlagEnabled) {
            return purchase({
                checkoutData,
                selectedRate,
                monthlyStarts,
                facility,
                stripeToken,
                stripePaymentType,
                setNewCardAsDefault,
                includeVehicleInResponse,
                includeFacilityInResponse,
                source,
                rentalSourceReferrer,
                onSuccess,
                onError,
                routerPush,
            });
        }

        const ratePrice = !isMonthly
            ? get(selectedRate, 'price_breakdown.total_price') / 100
            : selectedRate.price / 100;
        const phoneNumberCleaned = user.phoneNumber
            ? user.phoneNumber.replace(/\D/g, '')
            : null;
        const isUserSavingNewCard =
            user.status === UserUtils.AUTH_STATE.USER &&
            (selectedCreditCard === 'new' || paypal) &&
            !purchaseForCustomer &&
            paymentRequired &&
            !facility.freePark;
        const isMonthlyRecurringNewCard =
            selectedCreditCard === 'new' &&
            isMonthly &&
            selectedRate.recurrable;
        const {searchUUID, actionUUID} = SearchTracking.getValues();
        const postData = {
            currency_type:
                currencyType.toLowerCase() ||
                UserUtils.CURRENCY_TYPES.DEFAULT.toLowerCase(),
            email: user.email,
            price: parseInt(Math.round(Number(ratePrice) * 100), 10),
            facility: facility.parking_spot_id,
            search_id: searchUUID,
            action_id: actionUUID,
            is_admin: user.isAdmin,
        };
        let isPaymentRequired = paymentRequired;

        if (selectedCreditCard === 'admin' || facility.freePark) {
            isPaymentRequired = false;
        }

        // This could go anywhere else below and could be consolidated
        // with other options that apply to non-EP reservations
        if (!eventPackageId) {
            postData.apply_sh_credit =
                user.status === UserUtils.AUTH_STATE.USER;
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'SpotHero-Version': '2024-04-01',
            },
        };
        const shExperimentVariations = StorageUtils.get(
            'sh-experiment-variations',
            'cookie'
        );
        let finalStripeToken = paypal ? null : stripeToken;

        if (paymentOverlay) {
            // purchase was made using the Apple/Google Pay payment overlay
            finalStripeToken = paymentOverlay.token.id;
        }

        if (includeFacilityInResponse) {
            postData.include = 'facility';
        }

        if (includeVehicleInResponse) {
            if (postData.include) {
                postData.include += ',vehicle_info';
            } else {
                postData.include = 'vehicle_info';
            }
        }

        // start/end times
        if (isMonthly) {
            postData.monthly_start_date = dayjs(monthlyStarts).format(
                Config.apiDateFormat
            );
            postData.rule = selectedRate.fullRule;
        } else if (!eventPackageId) {
            postData.starts = selectedRate.starts;
            postData.ends = selectedRate.ends;
            postData.rule_group_id = selectedRate.rule_group_id;
        }

        // event id
        if (eventId) {
            postData.event = eventId;
        }

        // event package id
        if (eventPackageId) {
            postData.event_package_id = eventPackageId;
        }

        if (
            selectedRate.rule_group_id ===
            FACILITY_TYPES.RATE_EXCEPTIONS.EVENT_RATE
        ) {
            delete postData.rule_group_id;
        }

        // phone number
        if (user.phoneNumber && !phoneNumberCleaned) {
            postData.phone_number = user.phoneNumber;
        } else {
            if (phoneNumberCleaned) {
                postData.phone_number = phoneNumberCleaned;
            }
        }

        // promo code
        if (promoCode && !eventPackageId) {
            postData.promo_code = promoCode.code;
        }

        // payment data
        if (finalStripeToken) {
            postData.stripe_token = finalStripeToken;

            if (stripePaymentType && stripePaymentType !== 'cc') {
                postData.stripe_card_type = stripePaymentType;

                // Android/Google Pay requires these two fields on the BE for whatever reason...
                if (
                    stripePaymentType ===
                    CheckoutUtils.PAYMENT_REQUEST_TYPES.GOOGLE_PAY
                ) {
                    postData.google_instant_user_email = user.email;
                    postData.google_instant_card_info = 'Google Pay Card';
                }
            } else {
                postData.default_card =
                    user.status === UserUtils.AUTH_STATE.USER &&
                    user.justCreated
                        ? true
                        : setNewCardAsDefault;
            }
        } else if (paypal) {
            postData.braintree_payment_method_nonce = paypal.nonce;
            postData.paypal_email = paypal.email;
            postData.payment_processor = 'braintree';
        } else if (isPaymentRequired) {
            postData.card_external_id = selectedCreditCard;
        }

        // save new card
        if (isUserSavingNewCard || isMonthlyRecurringNewCard) {
            postData.save_card = true;
        }

        // purchase for customer
        if (purchaseForCustomer) {
            postData.purchase_for_customer = true;
            postData.purchase_for_customer_description = purchaseForCustomerDescription;

            if (initialReservationId) {
                postData.referenced_rental_id = initialReservationId;
            }
        }

        // purchase on behalf of customer
        if (purchaseOnBehalfOfCustomer) {
            postData.purchase_on_behalf_of_customer = true;
        }

        if (affiliate) {
            postData.affiliate = affiliate;
        }

        if (referralSource) {
            postData.referral_source = referralSource;
        }

        // SMS Parking Pass
        if (smsParkingPass && phoneNumberCleaned) {
            postData.sms_parking_pass = true;
        }

        if (
            vehicleSelectPresent ||
            licensePlateRequired ||
            isMonthly ||
            eventPackageId
        ) {
            // vehicle data
            if (vehicleProfileId) {
                postData.vehicle_profile_id = vehicleProfileId;
            } else if (vehicleInfoId) {
                postData.vehicle_info_id = vehicleInfoId;

                if (vehicleMake && vehicleModel) {
                    postData.vehicle_make = vehicleMake;
                    postData.vehicle_model = vehicleModel;
                    postData.set_vehicle_default = Boolean(
                        saveVehicleAsDefault
                    );
                }
            } else if (unlistedModel) {
                postData.unlisted_model = unlistedModel;
            }

            // license plate
            if (
                (licensePlateRequired ||
                    !user.id ||
                    (eventPackageId && selectedLicensePlate)) &&
                !vehicleProfileId
            ) {
                postData.license_plate_str = selectedLicensePlate;

                // unknown license plate flag
                if (
                    selectedLicensePlate === 'DONTKNOW' ||
                    !selectedLicensePlate
                ) {
                    postData.license_plate_unknown = true;
                }

                if (licensePlateState) {
                    postData.license_plate_state = licensePlateState;
                }

                // new license plate
                if (newLicensePlate && selectedLicensePlate) {
                    postData.license_plate_is_default = Boolean(
                        newLicensePlateIsDefault
                    );

                    if (newLicensePlateName) {
                        postData.license_plate_name = newLicensePlateName;
                    }

                    if (!purchaseForCustomer) {
                        postData.save_license_plate = true;
                    }
                }
            }

            if (licensePlateUnknown && (!eventPackageId || !vehicleProfileId)) {
                postData.license_plate_unknown = true;
            }
        }

        if (vehicleColor) {
            postData.vehicle_color = vehicleColor;
        }

        if (isMonthly) {
            postData.accept_terms_and_conditions = true;
        }

        if (contractFullName) {
            postData.contract_full_name = contractFullName;
        }

        // Force redundant non-recurring monthly reservation?
        if (forceRedundantMonthly) {
            postData.force_redundant_monthly = true;
        }

        if (source) {
            postData.rental_source_title = source;
        }

        if (rentalSourceReferrer) {
            postData.rental_source_referrer = rentalSourceReferrer;
        } else {
            const referrerCookie = StorageUtils.get('sh-referrer', 'cookie');

            if (referrerCookie) {
                postData.rental_source_referrer = referrerCookie;
            }
        }

        if (shExperimentVariations) {
            config.headers = {
                ...config.headers,
                'x-spothero-experiments': JSON.stringify(
                    shExperimentVariations
                ),
            };
        }

        postData.mixpanel_id = SegmentUtils.getAnonymousId();

        const reservationEndpoint = eventPackageId
            ? 'event-packages/reserve/'
            : 'reservations/reserve/';

        try {
            const {
                data: {
                    data: {reservation, order_id: orderId, access_key},
                },
            } = await APIUtils.post(reservationEndpoint, postData, config);

            const accessKey = eventPackageId
                ? access_key
                : reservation?.access_key;

            if (onSuccess) {
                await onSuccess({checkoutData, reservation, rentalId: orderId});
            }

            // remove any existing referrer cookies on purchase
            StorageUtils.remove('sh-referrer', 'cookie');

            // hide the Apple/Google Pay payment overlay
            if (paymentOverlay) {
                paymentOverlay.complete('success');
            }

            if (!(source && includes(source, 'widget'))) {
                redirectToConfirmationPage({orderId, accessKey, routerPush});
            }
        } catch (error) {
            // We want to log code failures here, not network failures
            if (error instanceof Error) {
                ErrorUtils.sendSentryException(error);
            }

            const {errors, extra} = getErrorsFromResponse(error);

            // show error in the Apple/Google Pay payment overlay so that it doesn't look like it keeps trying to process
            if (paymentOverlay) {
                paymentOverlay.complete('fail');
            }

            onError(errors, extra);
        }
    },
    /**
     * Add bulk reservation to spothero
     * This function similar to ReservationAPI.add.
     * This is created to avoid increasing the complexity of add reservation.
     * The start, end and rate is obtained from the bulkPowerBookingRates.
     * Rest of the functionality remains same as transient booking. No monthly or Event package is required.
     *
     * @function addBulkReservation
     * @param {object} param Top level param
     * @param {CheckoutData} param.checkoutData Data from checkout form
     * @param {SelectedRate} param.bulkPowerBookingRates All the rates for the dates selected for the spot
     * @param {object} param.facility - Facility object
     * @param {object} param.stripeToken - object from stripe
     * @param {string} param.stripePaymentType - stripe payment type
     * @param {boolean} param.setNewCardAsDefault - is this new card default
     * @param {boolean} param.includeVehicleInResponse - is vehicle to be to be in response
     * @param {boolean} param.includeFacilityInResponse - is facility to be to be in response
     * @param {object} param.source - Rental source
     * @param {object} param.rentalSourceReferrer - Rental source referrer
     * @param {Function} param.onSuccess - callback on success
     * @param {Function} param.onError - callback on error
     * @param {Function} param.routerPush - callback for router push
     * @param {Function} param.setFailedPeriods - callback for setting failed periods
     * @see {@link https://spothero.com/api/v1/docs/endpoints/#!/Reservations/post_reservations_reserve|Documentation}
     * @returns {void}
     * @todo Facility object deficition
     */
    async addBulkReservation({
        checkoutData,
        bulkPowerBookingRates,
        facility,
        stripeToken = false,
        stripePaymentType = 'cc',
        setNewCardAsDefault = false,
        includeVehicleInResponse = false,
        includeFacilityInResponse = false,
        source = null,
        rentalSourceReferrer = null,
        onSuccess,
        onError,
        routerPush,
        setFailedPeriods,
    }) {
        const {
            currencyType,
            user,
            paymentRequired,
            purchaseForCustomer,
            purchaseForCustomerDescription,
            purchaseOnBehalfOfCustomer,
            initialReservationId,
            affiliate,
            selectedCreditCard,
            licensePlateRequired,
            selectedLicensePlate,
            licensePlateState,
            newLicensePlate,
            newLicensePlateName,
            newLicensePlateIsDefault,
            referralSource,
            smsParkingPass,
            vehicleInfoId,
            vehicleProfileId,
            vehicleMake,
            vehicleModel,
            vehicleColor,
            unlistedModel,
            saveVehicleAsDefault,
            contractFullName,
            paymentOverlay,
            paypal,
            licensePlateUnknown,
            vehicleSelectPresent,
        } = checkoutData;

        const phoneNumberCleaned = user.phoneNumber
            ? user.phoneNumber.replace(/\D/g, '')
            : null;
        const isUserSavingNewCard =
            user.status === UserUtils.AUTH_STATE.USER &&
            (selectedCreditCard === 'new' || paypal) &&
            !purchaseForCustomer &&
            paymentRequired;

        let isPaymentRequired = paymentRequired;

        if (selectedCreditCard === 'admin' || facility.freePark) {
            isPaymentRequired = false;
        }

        const {searchUUID, actionUUID} = SearchTracking.getValues();

        const postData = {
            currency_type:
                currencyType.toLowerCase() ||
                UserUtils.CURRENCY_TYPES.DEFAULT.toLowerCase(),
            email: user.email,
            facility: facility.parking_spot_id,
            search_id: searchUUID,
            action_id: actionUUID,
            is_admin: user.isAdmin,
        };

        // Due to parallel calls to the BE, it might create race conditions for credit. Hence credit is not available for power booking.
        postData.apply_sh_credit = false;

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'SpotHero-Version': '2024-04-01',
            },
        };
        const shExperimentVariations = StorageUtils.get(
            'sh-experiment-variations',
            'cookie'
        );
        let finalStripeToken = paypal ? null : stripeToken;

        if (paymentOverlay) {
            // purchase was made using the Apple/Google Pay payment overlay
            finalStripeToken = paymentOverlay.token.id;
        }

        if (includeFacilityInResponse) {
            postData.include = 'facility';
        }

        if (includeVehicleInResponse) {
            if (postData.include) {
                postData.include += ',vehicle_info';
            } else {
                postData.include = 'vehicle_info';
            }
        }

        // phone number
        if (user.phoneNumber && !phoneNumberCleaned) {
            postData.phone_number = user.phoneNumber;
        } else {
            if (phoneNumberCleaned) {
                postData.phone_number = phoneNumberCleaned;
            }
        }

        // license plate
        if (licensePlateRequired || !user.id || !vehicleProfileId) {
            postData.license_plate_str = selectedLicensePlate;

            // unknown license plate flag
            if (selectedLicensePlate === 'DONTKNOW') {
                postData.license_plate_unknown = true;
            }

            if (licensePlateState) {
                postData.license_plate_state = licensePlateState;
            }

            // new license plate
            if (newLicensePlate) {
                postData.license_plate_is_default = Boolean(
                    newLicensePlateIsDefault
                );

                if (newLicensePlateName) {
                    postData.license_plate_name = newLicensePlateName;
                }

                if (!purchaseForCustomer) {
                    postData.save_license_plate = true;
                }
            }
        }

        if (licensePlateUnknown) {
            postData.license_plate_unknown = true;
        }

        // payment data
        if (finalStripeToken) {
            postData.stripe_token = finalStripeToken;

            if (stripePaymentType && stripePaymentType !== 'cc') {
                postData.stripe_card_type = stripePaymentType;

                // Android/Google Pay requires these two fields on the BE for whatever reason...
                if (
                    stripePaymentType ===
                    CheckoutUtils.PAYMENT_REQUEST_TYPES.GOOGLE_PAY
                ) {
                    postData.google_instant_user_email = user.email;
                    postData.google_instant_card_info = 'Google Pay Card';
                }
            } else {
                postData.default_card =
                    user.status === UserUtils.AUTH_STATE.USER &&
                    user.justCreated
                        ? true
                        : setNewCardAsDefault;
            }
        } else if (isPaymentRequired) {
            postData.card_external_id = selectedCreditCard;
        }

        // purchase for customer
        if (purchaseForCustomer) {
            postData.purchase_for_customer = true;
            postData.purchase_for_customer_description = purchaseForCustomerDescription;

            if (initialReservationId) {
                postData.referenced_rental_id = initialReservationId;
            }
        }

        // purchase on behalf of customer
        if (purchaseOnBehalfOfCustomer) {
            postData.purchase_on_behalf_of_customer = true;
        }

        if (affiliate) {
            postData.affiliate = affiliate;
        }

        if (referralSource) {
            postData.referral_source = referralSource;
        }

        // SMS Parking Pass
        if (smsParkingPass && phoneNumberCleaned) {
            postData.sms_parking_pass = true;
        }

        if (vehicleSelectPresent || licensePlateRequired) {
            // vehicle data
            if (vehicleProfileId) {
                postData.vehicle_profile_id = vehicleProfileId;
            } else if (vehicleInfoId) {
                postData.vehicle_info_id = vehicleInfoId;

                if (vehicleMake && vehicleModel) {
                    postData.vehicle_make = vehicleMake;
                    postData.vehicle_model = vehicleModel;
                    postData.set_vehicle_default = Boolean(
                        saveVehicleAsDefault
                    );
                }
            } else if (unlistedModel) {
                postData.unlisted_model = unlistedModel;
            }
        }

        if (vehicleColor) {
            postData.vehicle_color = vehicleColor;
        }

        if (contractFullName) {
            postData.contract_full_name = contractFullName;
        }

        if (source) {
            postData.rental_source_title = source;
        }

        if (rentalSourceReferrer) {
            postData.rental_source_referrer = rentalSourceReferrer;
        } else {
            const referrerCookie = StorageUtils.get('sh-referrer', 'cookie');

            if (referrerCookie) {
                postData.rental_source_referrer = referrerCookie;
            }
        }

        if (shExperimentVariations) {
            config.headers = {
                ...config.headers,
                'x-spothero-experiments': JSON.stringify(
                    shExperimentVariations
                ),
            };
        }

        // This param is set only for power booking since its a bulk purchase
        postData.bulk_purchase = true;

        const reservationEndpoint = 'reservations/reserve/';

        const pbOrders = [];
        const failedPowerBookingPeriods = [];
        // Reset failed periods when users are navigating using back button
        setFailedPeriods(failedPowerBookingPeriods);
        const errors = [];
        const finalReservations = [];

        /* Bulk reservation right now does not support paypal, guest checkout, spothero credit and promocodes.
        For guest checkout the stripe token cannot be reused for multiple reservations and the card used by the guest is hidden which cannot be used for repurchase of other reservation after the first reservation is made.
        For similar uncertainity paypal is also disabled.
        Hence if its a new card, the card is first added to the user account and then reservation is made to avoid the token conflict.
        Ideally, we need single endpoint to handle this in the backend which is TBD.
        */

        try {
            if (
                finalStripeToken &&
                !paymentOverlay &&
                selectedCreditCard === 'new'
            ) {
                const postCreditCardData = {
                    stripe_token: finalStripeToken,
                    is_default: false,
                };
                const {
                    data: {
                        data: {card_external_id},
                    },
                } = await APIUtils.post(
                    `users/${user.id}/credit-cards/`,
                    postCreditCardData,
                    config
                );

                postData.card_external_id = card_external_id;
                postData.stripe_token = null;
            }

            const promises = bulkPowerBookingRates.map(({rates = []}) => {
                const {quote} = (rates.length && rates[0]) || {};
                const order = (quote?.order.length && quote?.order[0]) || {};
                postData.starts = dayjs(order?.starts)
                    .tz(facility?.timezone)
                    .format(Config.apiDateTimeFormat);
                postData.ends = dayjs(order?.ends)
                    .tz(facility?.timezone)
                    .format(Config.apiDateTimeFormat);
                if (
                    order?.rate_id !== FACILITY_TYPES.RATE_EXCEPTIONS.EVENT_RATE
                ) {
                    postData.rule_group_id = order?.rate_id;
                }
                postData.price = quote?.total_price?.value;
                postData.mixpanel_id = SegmentUtils.getAnonymousId();

                return APIUtils.post(reservationEndpoint, postData, config);
            });

            const results = await Promise.allSettled(promises);

            results.forEach(result => {
                if (result.status === 'fulfilled') {
                    const {
                        value: {
                            data: {
                                data: {reservation, order_id: orderId},
                            },
                        },
                    } = result;
                    const {access_key: accessKey} = reservation;
                    pbOrders.push(`${orderId}:${accessKey}`);
                    finalReservations.push(reservation);
                    return [...pbOrders, errors];
                } else {
                    const {
                        reason: {
                            config: {data},
                        },
                    } = result;

                    const params = UrlUtils.parseParams(data);

                    failedPowerBookingPeriods.push({
                        starts: params?.starts,
                        ends: params?.ends,
                    });

                    errors.push(result?.reason?.data);
                }
            });
            if (pbOrders.length && !(source && includes(source, 'widget'))) {
                // remove any existing referrer cookies on purchase
                StorageUtils.remove('sh-referrer', 'cookie');

                // hide the Apple/Google Pay payment overlay
                if (paymentOverlay) {
                    paymentOverlay.complete('success');
                }

                // This is set to show the failed power booking periods in the user flow from checkout to confirmation
                // This will not be shown when user refreshes the page as the state will be lost.
                if (failedPowerBookingPeriods.length) {
                    setFailedPeriods(failedPowerBookingPeriods);
                }

                await onSuccess({
                    checkoutData,
                    reservation: finalReservations[0],
                    // Required for only event packages
                    rentalId: '',
                    powerBookingReservationsIds: finalReservations.map(
                        r => r.reservation_id
                    ),
                    failedPowerBookingPeriodsLength:
                        failedPowerBookingPeriods.length,
                });

                if (!Config.isDev) {
                    window.location.href = `/receipt/multiple-days?${UrlUtils.createQueryString(
                        {
                            pbOrders,
                            failedPeriods: failedPowerBookingPeriods.map(
                                failedPeriod =>
                                    `${failedPeriod.starts};${failedPeriod.ends}`
                            ),
                        }
                    )}`;
                } else {
                    routerPush(
                        `/confirmation?${UrlUtils.createQueryString({
                            pbOrders,
                        })}`
                    );
                }
            } else {
                if (errors?.length) {
                    errors.forEach(error => {
                        // We want to log code failures here, not network failures
                        if (error instanceof Error) {
                            ErrorUtils.sendSentryException(error);
                        }
                    });
                }
                const {errors: defaultError, extra} = getErrorsFromResponse();

                // show error in the Apple/Google Pay payment overlay so that it doesn't look like it keeps trying to process
                if (paymentOverlay) {
                    paymentOverlay.complete('fail');
                }

                // If all reservations failed, then dispatch checkout failed event
                if (errors.length === bulkPowerBookingRates.length) {
                    onError(defaultError, extra);
                }
            }
        } catch (error) {
            // We want to log code failures here, not network failures
            if (error instanceof Error) {
                ErrorUtils.sendSentryException(error);
            }

            const {errors, extra} = getErrorsFromResponse(error);

            // show error in the Apple/Google Pay payment overlay so that it doesn't look like it keeps trying to process
            if (paymentOverlay) {
                paymentOverlay.complete('fail');
            }

            onError(errors, extra);
        }
    },

    /**
     * Customer Hero booking a replacement spot for a user with the money already spent.
     *
     * @function rebook
     * @param {object} param - Top level param
     * @param {CheckoutData} param.checkoutData - Data from checkout form
     * @param {SelectedRate} param.selectedRate - Selected Rate for spot
     * @param {object} param.facility - facility object
     * @param {string} param.rebookReservationId - original reservation id
     * @param {Function} param.onError callback on error
     * @param {SelectedRate} param.routerPush - callback for router push
     * @returns {void}
     * @todo Facility typedef
     * @todo link to hero?
     */
    async rebook({
        checkoutData,
        selectedRate,
        facility,
        rebookReservationId,
        onError,
        routerPush,
    }) {
        const {
            currencyType,
            isMonthly,
            licensePlateRequired,
            licensePlateState,
            newLicensePlateIsDefault,
            saveVehicleAsDefault,
            selectedLicensePlate,
            user,
            vehicleColor,
            vehicleInfoId,
            vehicleProfileId,
            vehicleMake,
            vehicleModel,
            vehicleSelectPresent,
            supportsOversizeFeeType,
            purchaseForCustomerDescription,
            licensePlateUnknown,
        } = checkoutData;
        const price = !isMonthly
            ? get(selectedRate, 'price_breakdown.total_price')
            : selectedRate.price;
        const postData = {
            currency_type: currencyType.toLowerCase(),
            email: user.email,
            ends: selectedRate.ends,
            original_rental_id: rebookReservationId,
            price,
            rebook_facility_id: facility.parking_spot_id,
            rule_group_id: selectedRate.rule_group_id,
            spothero_user_id: user.id,
            starts: selectedRate.starts,
            ...((vehicleSelectPresent ||
                supportsOversizeFeeType ||
                licensePlateRequired) && {
                vehicle_info_id: vehicleInfoId,
                vehicle_profile_id: vehicleProfileId,
                vehicle_make: vehicleMake,
                vehicle_model: vehicleModel,
                vehicle_color: vehicleColor,
                set_vehicle_default: Boolean(saveVehicleAsDefault),
            }),
            ...(user.phoneNumber && {
                phone_number: user.phoneNumber,
            }),
            ...(licensePlateRequired &&
                selectedLicensePlate &&
                selectedLicensePlate !== 'DONTKNOW' && {
                    license_plate_str: selectedLicensePlate,
                    license_plate_state: licensePlateState,
                    license_plate_is_default: newLicensePlateIsDefault,
                }),
            ...(licensePlateRequired &&
                licensePlateUnknown && {
                    license_plate_unknown: true,
                }),
            rebook_reason: purchaseForCustomerDescription,
        };

        if (
            selectedRate.rule_group_id ===
            FACILITY_TYPES.RATE_EXCEPTIONS.EVENT_RATE
        ) {
            delete postData.rule_group_id;
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };

        try {
            const {
                data: {
                    data: {
                        refund_amount: refundAmount,
                        order_id: orderId,
                        access_key: accessKey,
                    },
                },
            } = await APIUtils.post('hero/rebook/', postData, config);

            if (refundAmount > 0) {
                const currencySymbol = FormatUtils.currencySymbol(
                    selectedRate.currency_type
                );

                // eslint-disable-next-line no-alert
                alert(
                    `Please refund ${currencySymbol}${refundAmount} to the customer via stripe.`
                );
            }

            redirectToConfirmationPage({orderId, accessKey, routerPush});
        } catch (error) {
            const {errors, extra} = getErrorsFromResponse(error);

            onError(errors, extra);
        }
    },
};

export default ReservationAPI;

// TYPES
// TODO MR - Move into own folder - would require moving api's around

/**
 * @typedef {object} CheckoutData
 * @property {Currency} currencyType type of currency used
 * @property {object} user user object
 * @property {string} promoCode promocode applied
 * @property {boolean} isMonthly is a monthly reservation
 * @property {string} eventId id of event
 * @property {boolean} paymentRequired is payment required
 * @property {boolean} purchaseForCustomer is this purchased for a customer
 * @property {string} purchaseForCustomerDescription description of why it was purchased for customer
 * @property {boolean} purchaseOnBehalfOfCustomer is this purchased by a proxy
 * @property {string} initialReservationId reservation id
 * @property {object} affiliate affiliate code
 * @property {string} selectedCreditCard selected credit card either ['new', 'admin', cardId]
 * @property {boolean} licensePlateRequired is license plate required
 * @property {string} selectedLicensePlate which plate is selected - either ['DONTKNOW', plate string (ex CJB123K)]
 * @property {string} licensePlateState state license plate is assigned to
 * @property {string} newLicensePlate new plate string
 * @property {string} newLicensePlateName new plate nick name
 * @property {string} newLicensePlateIsDefault is this plate default
 * @property {string} referralSource source of referral
 * @property {boolean} smsParkingPass is this an sms parking pass
 * @property {boolean} forceRedundantMonthly should this be a redundant monthly charge
 * @property {string} vehicleInfoId vehicle id
 * @property {string} vehicleProfileId vehicle id from user profile
 * @property {string} vehicleMake vehicle make
 * @property {string} vehicleModel vehicle model
 * @property {string} vehicleColor vehicle color
 * @property {string} unlistedModel vehicle model wasn't listed so we have this override
 * @property {boolean} saveVehicleAsDefault is new vehicle default
 * @property {string} contractFullName users full name
 * @property {object} paymentOverlay object used for google pay and apple pay to show relevant data
 * @property {object} spot spot information
 * @property {object} paypal object used for paypal to store relevant data
 */

/**
 * @typedef {object} PriceItem
 * @property {number} price - price of item
 * @property {string} type - type of item
 * @property {string} short_description - short description
 * @property {string} full_description - full description
 */

/**
 * @typedef {('usd'|'cad')} Currency
 */

/**
 * @typedef {object} PriceBreakdown
 * @property {Array<PriceItem>} items - items to be broken down
 * @property {Currency} currency - type of currency
 * @property {number} total_price - price of purchase
 */

/**
 * @typedef {object} Amenity
 * @property {string} slug - slug of Amenity
 * @property {string} name - name of Amenity
 * @property {number} sort_order - order to show
 * @property {boolean} visible - should I show this
 * @property {string} icon_url - url for icon
 */

/**
 * @typedef {object} AccessHour
 * @property {('247' | any)} type - type of access
 * @todo Build out more
 */

/**
 * @typedef {object} SelectedRate
 * @property {string} rule_type - type of rule
 * @property {string} title - title of rate
 * @property {number} rule_id - id of rule
 * @property {number} price - price
 * @property {number} display_price - price shown
 * @property {boolean} unavailable - is Unavailable
 * @property {string} unavailable_reason - why is it unavailable
 * @property {number} rule_group_id - id of rule group
 * @property {number|null} eventId - id of event
 * @property {string} rule_trail - rule trail string ex "47119.47119.47119.47119.47119.47119"
 * @property {string} starts - iso string ex 2021-10-14T14:00
 * @property {string} ends - iso string ex 2021-10-14T14:00
 * @property {string} url - url of checkout
 * @property {Currency} currency_type - ex ['usd' or 'cad']
 * @property {string} currency_symbol - symbol to show for currency
 * @property {PriceBreakdown|object} price_breakdown - breakdown of price
 * @property {boolean} online_commuter_rate - is there a commuter rate
 * @property {string|null} online_commuter_rate_description - description of commuter rate
 * @property {string|null} online_commuter_rate_enter_start - time to enter
 * @property {string|null} online_commuter_rate_enter_end - time to leave
 * @property {Array<Amenity>} amenities - List of Amenities
 * @property {string} parking_type - type of parking
 * @property {boolean} hidden - hide rate
 * @property {boolean} newMonthlyRate - is this new
 * @property {string} fullUrl - relative url to checkout with rate
 * @property {Array<AccessHour>} access_hours_formatted - list of access hours for facilities
 * @property {number} fullRule - full rule number ??
 */
